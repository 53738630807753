<template>
    <!-- Locked -->
    <div v-if="action('locked')" :class="overlay ? 'py-3 sm:py-5 px-5' : 'pb-3 sm:pb-5'" class="space-y-2">
        <p class="inline-flex text-gray-700 dark:text-gray-300 text-sm">
            <icon icon="lock-closed" classes="w-5 h-5 mr-1 shrink-0"></icon>
            Löse{{ $addressingUser('','n Sie') }} {{ $config.setup.game.success_to_unlock }} Sudoku{{ $config.setup.game.success_to_unlock !== 1 ? 's' : '' }} auf der Stufe "{{ $config.setup.game.levels[$store.getters.getNextLevel(level,-1)] }}", um "{{ $config.setup.game.levels[level] }}" freizuschalten.
        </p>
        <p class="inline-flex text-gray-700 dark:text-gray-300 text-sm items-center mr-2">
            <icon icon="check" classes="w-5 h-5 mr-1 shrink-0"></icon>
            {{ $store.getters.getStats.levels[$store.getters.getNextLevel(level,-1)].games }}/{{ $config.setup.game.success_to_unlock }} auf Stufe "{{ $config.setup.game.levels[$store.getters.getNextLevel(level,-1)] }}" gelöst
        </p>
        <router-link :to="{name:'Home',params: $store.getters.getNextLevel(level,-1) !== Object.keys($config.setup.game.levels)[0] ? {level: $config.setup.game.levels[$store.getters.getNextLevel(level,-1)].toLowerCase()} : null}" class="group btn-primary inline-flex items-center whitespace-nowrap">
            <icon icon="lock-open" classes="w-5 h-5 group-hover:text-brand-500 text-brand-contrast mr-2"></icon>
            Lösen und freischalten
        </router-link>
    </div>
    <!-- /Locked -->
    <!-- Unlocked -->
    <template v-else>
        <div class="flex items-center justify-center py-3 px-5 sm:p-5 space-x-3">
            <router-link v-if="action('stats','game')" :to="{name:'Stats'}" title="Statistiken" class="inline-flex btn btn-default whitespace-nowrap">
                <icon icon="chart-bar" classes="-ml-1 mr-2 w-5 h-5"></icon>
                Statistik<span class="sm:hidden">&nbsp;anzeigen</span>
            </router-link>
            <button v-if="action('share')" @click="shareResult(game.nr,game.level,game.time,game.errors,game.sudoku)" class="inline-flex btn btn-primary whitespace-nowrap" type="button">
                <icon v-if="$store.getters.canShare" icon="share" classes="-ml-1 mr-2 w-5 h-5"></icon>
                <icon v-else icon="document-duplicate" classes="-ml-1 mr-2 w-5 h-5"></icon>
                <span class="first-letter:capitalize"><span class="sm:hidden">Erfolg&nbsp;</span>teilen</span>
            </button>
            <router-link v-if="action('continue','overlay')" @click="continueGame" :to="routeContinue()" class="btn btn-default whitespace-nowrap inline-block">
                "{{ $config.setup.game.levels[level] }}" {{ !game || game.time > 0 ? "fortsetzen" : "starten" }}
            </router-link>
            <router-link v-else-if="action('archive','overlay') && !game.end" @click="continueGame" :to="routeContinue(true,game.date)" class="btn btn-default whitespace-nowrap inline-block">
                Sudoku {{ selectedGame.time > 0 ? "fortsetzen" : "starten" }}
            </router-link>
            <router-link v-if="action('archive','overlay')" :to="{name:'Archive'}" tag="button" class="btn btn-default whitespace-nowrap inline-flex items-center justify-self-end">
                Zum Archiv
                <icon icon="chevron-right" classes="h-4 w-4 ml-1 -mr-1"></icon>
            </router-link>
        </div>
        <!-- Promote archive & register -->
        <action-promote-archive v-if="action('promoteArchive','overlay')" class="py-3 border-t border-gray-200 bg-gray-100 px-5 sm:p-5 space-x-2 dark:bg-gray-700 dark:border-gray-600"></action-promote-archive>
        <!-- /Promote archive & register -->
        <!-- Rotate publications -->
        <action-rotate-publications v-else-if="action('promotePublication','overlay')" class="py-3 flex border-t border-gray-200 bg-gray-100 px-5 sm:p-5 space-x-2 dark:bg-gray-700 dark:border-gray-600"></action-rotate-publications>
        <!-- /Rotate publications -->
        <div v-if="action('next') || action('countdown')" class="border-t dark:border-gray-600" :class="overlay ? 'px-5 py-3 sm:p-5' : 'pt-3 sm:pt-5'">
            <template v-if="action('next')">
                <div v-if="overlay && $store.getters.getStat('levels')[level].games === $config.setup.game.success_to_unlock" class="mb-2">
                    <div class="bg-green-100 dark:bg-green-200 contrast:!bg-orange-100 text-green-900 contrast:!text-orange-900 inline-flex items-center text-sm p-1.5 rounded">
                        <icon icon="rocket-launch" classes="mr-1 h-5 w-5 text-green-700 shrink-0"></icon>
                        Stufe freigeschaltet: <span class="font-semibold ml-1">{{ $config.setup.game.levels[$store.getters.getNextLevel(level)] }}</span>
                    </div>
                </div>
                <div class="flex items-center" :class="!overlay ? 'justify-center' : null">
                    <p class="text-sm text-gray-900 dark:text-gray-300 mr-2">
                        Löse{{ $addressingUser('','n Sie') }} als nächstes ein Sudoku auf
                    </p>
                    <router-link :to="{name:'Home',params:{level:$config.setup.game.levels[$store.getters.getNextLevelToPlay(level)].toLowerCase()}}" class="btn-primary shrink-0 whitespace-nowrap ml-1">Stufe "{{ $config.setup.game.levels[$store.getters.getNextLevelToPlay(level)] }}"</router-link>
                </div>
            </template>
            <vue-countdown v-else-if="action('countdown')" @end="onCountdownEnd" :time="tilNextDay()" v-slot="{ hours, minutes, seconds }">
                <time v-if="hours > 0 || minutes > 0 || seconds > 0" class="block text-center text-gray-900 text-lg dark:text-gray-300">Neues Sudoku in <strong class="whitespace-nowrap">{{ hours }}h {{ minutes }}m und {{ seconds }}s</strong>.</time>
                <Spinner v-else classes="-mt-1 mx-auto my-0 w-8 h-8"></Spinner>
            </vue-countdown>
        </div>
    </template>
    <!-- /Unlocked -->
</template>

<script>
import Share from "../mixins/share.mix"
import VueCountdown from "@chenfengyuan/vue-countdown"
import Icon from "../vendor/publisher/components/Icon"
import Spinner from "../vendor/publisher/components/Spinner.vue"
import ActionPromoteArchive from "../vendor/publisher/components/ActionPromoteArchive.vue";
import ActionRotatePublications from "../vendor/publisher/components/ActionRotatePublications.vue";

export default {
    name: "Actions",
    props: ["currentGame","level","overlay"],
    emits: ["close","continue"],
    mixins: [
        Share
    ],
    components: {
        ActionRotatePublications,
        ActionPromoteArchive,
        Icon,
        Spinner,
        VueCountdown
    },
    computed: {
        /**
         *
         * @returns {any}
         */
        game() {
            if(this.currentGame.level === this.level) {
                return this.currentGame;
            }

            const gameById = this.$store.getters.getGameById(this.level);

            if(gameById) {
                return gameById;
            }
            else {
                return {
                    archive: false,
                    date: this.$store.getters.today,
                    end: false,
                    time: 0
                }
            }

        },
    },
    methods: {
        /**
         *
         * @param elm
         */
        closeOverlay(elm) {
            this.$emit("close",elm);
        },
        /**
         *
         * @param elm
         */
        continueGame(elm) {
            this.$emit("continue",elm);
        },
        /**
         *
         * @param archive
         * @param date
         * @returns {{name: string, params: ({level: string}|null)}}
         */
        routeContinue(archive = false,date = null) {

            const route = {
                name: "Home",
                params: this.level !== Object.keys(this.$config.setup.game.levels)[0] ? {
                    level: this.$config.setup.game.levels[this.level].toLowerCase()
                } : null
            }

            if(archive) {
                route["query"] = {
                    date: date
                }
            }

            return route;

        },
        /**
         *
         * @param action
         * @param display
         */
        action(action = null,display = "always"/* ['always','overlay','game']*/) {
            if((display === "overlay" && !this.overlay) || (display === "game" && this.overlay)) {
                return false;
            }

            // If "level" is valid, level contains always the properties "end" & "locked"
            const level = this.$store.getters.getLevel(this.level);
            const random = Math.floor(Math.random() * 2);

            const actions = {
                "countdown": () => {
                    return !this.game.archive && !level.locked && this.$store.getters.getRemainingGames === 0;
                },
                "locked": () => {
                    return level.locked;
                },
                "promoteArchive": () => {
                    // Todo: and games are left
                    return random === 0 && !this.game.archive && this.$archive.enabled() && this.game.end && this.$store.getters.getRemainingGames === 0;
                },
                "promotePublication": () => {
                    return this.hasConnection && this.$config["publications"] && this.$config["publications"].length > 0 && this.game.end && this.$store.getters.getRemainingGames === 0;
                },
                "continue": () => {
                    return !this.game.archive && !level.end && !level.locked && this.$store.getters.getRemainingGames > 0;
                },
                "next": () => {
                    return !this.game.archive && this.$store.getters.getRemainingGames > 0 && !level.locked && level.end;
                },
                "archive": () => {
                    return this.game.archive;
                },
                "stats": () => {
                    return this.game.archive && this.game.end || level.end;
                },
                "share": () => {
                    return this.game.archive && this.game.end || level.end;
                },
                "ad": () => {
                    return (level.end && this.$store.getters.getRemainingGames > 0) || (this.game.archive && this.game.end);
                }
            };

            return actions[action]();
        }
    }
}
</script>
