import { routes } from "./router/sudoku"
import { setMeta } from "@publisher/router/index.js"

export default {

    install: (app, options) => {

        const { router, store } = options;

        if (!store) {
            throw new Error("Please provide a store object.");
        }

        const _ = store.getters.getConfig;

        if (!_.hasOwnProperty("identifier")) {
            throw new Error('Object "config" has no property "identifier".');
        }

        if (!router) {
            throw new Error("Please provide router.");
        }

        for (let route of routes) {
            if(_.common.with_authentication) {
                route.meta.requiresAuth = true;
            }

            route.meta = setMeta(route,_.content.meta,route.name)

            router.addRoute(route);
        }
    },

};
