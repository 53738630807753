import axios from "axios"
import { get, set, del, _keys, getAll } from "@publisher/idb.js"
import { config, preloadPuzzles } from "../config";

export let game = {
    state: () => ({
        game: {
            nr: 0,
            level: "easy",
            sudoku: [],
            score: [],
            date: null,
            end: false,
            errors: 0,
            setup: {
                help: false,
                candidate: false
            },
            time: 0,
            focus: {
                row: 0,
                col: 0
            },
            archive: false,
        },
        games: {},
        puzzles: {}
    }),
    actions: {
        /**
         *
         * @param commit
         * @param getters
         * @param games
         */
        setGames: ({commit,getters},games) => {
            if(typeof getters.getConfig === "function") {
                config = getters.getConfig;
            }

            const today = getters.today,
                maxArchiveDate = new Date(new Date(today).setHours(0,0,0,0));

            maxArchiveDate.setDate(maxArchiveDate.getDate()-config.setup.game?.max_archive_days);

            Object.keys(games).forEach((date) => {

                const gameDate = new Date(new Date(date).setHours(0,0,0,0));

                if(gameDate >= maxArchiveDate) {
                    Object.keys(games[date]).forEach((level) => {
                        if(games[date][level].nr) {
                            commit("setGame", {
                                date: date,
                                end: games[date][level].end,
                                errors: games[date][level].errors || 0,
                                level: level,
                                nr: games[date][level].nr,
                                score: games[date][level].score,
                                setup: games[date][level].setup,
                                sudoku: games[date][level].sudoku,
                                time: games[date][level].time
                            });

                            if(date === today) {
                                commit("setLevel",{
                                    level: level,
                                    key: "end",
                                    val: games[date][level].end
                                });
                            }
                        }
                    });
                }
            });

        },
        /**
         *
         * @param dispatch
         * @param getters
         * @returns {Promise<unknown>}
         */
        getGames: ({dispatch,getters}) => {
            return new Promise(async (resolve, reject) => {
                try {
                    await dispatch("readIndexedDB");
                } catch (e) {}

                // Keine Spiele für heute vorhanden oder nicht alle Spiele vorgeladen
                if(!getters.getAllPuzzlesByDate(getters.today) || getters.countAllPuzzles < preloadPuzzles()) {
                    if(getters.getAllPuzzlesByDate(getters.today)) { // Spiele für heute vorhanden
                        resolve(true);
                    }

                    axios.get('/api/get')
                        .then((response) => {
                            dispatch("setPuzzles",response.data.puzzles ?? {})
                                .then(() => {
                                    resolve(true);
                                })
                                .catch(function (error) {
                                    reject(error);
                                });
                        })
                        .catch(function(error) {
                            reject(error);
                        });
                }
                else {
                    resolve(true);
                }
            });
        },
        /**
         *
         * @param commit
         * @param dispatch
         * @param nr
         * @returns {Promise<unknown>}
         */
        getGame: ({commit,dispatch},nr) => {
            return new Promise(async (resolve, reject) => {
                try {
                    let game = await get(parseInt(nr));
                    resolve({
                        date: game.date,
                        level: game.level,
                        nr: nr,
                        sudoku: game.sudoku,
                    });
                } catch (e) {
                    reject(null);
                }
            });
        },
        /**
         *
         * @param commit
         * @param state
         * @param puzzles
         * @returns {Promise<Awaited<unknown>[]>}
         */
        setPuzzles: ({commit,state},puzzles) => {
            let promises = [];

            if(Object.keys(puzzles).length > 0) { // Puzzles available?
                for(const [date,daily] of Object.entries(puzzles)) {
                    for (const puzzle of daily) {
                        promises.push(new Promise((resolve) => {
                            if(!state.puzzles[date] || !state.puzzles[date].find(e => e.nr === puzzle["nr"])) {
                                set(puzzle["nr"], {sudoku: puzzle["sudoku"], level: puzzle["level"], date: date}).finally(() => {
                                    commit("setPuzzle", {
                                        date: date,
                                        puzzle: puzzle
                                    });
                                    resolve();
                                });
                            }
                            else {
                                resolve("Puzzle already exists!");
                            }
                        }));
                    }
                }
            }

            return Promise.all(promises);
        },
        /**
         *
         * @param commit
         * @param getters
         * @returns {Promise<void>}
         */
        async readIndexedDB({commit,getters}) {
            let [keys, values] = await Promise.all([_keys(), getAll()]);
            const puzzles = {}, minPreloadDate = new Date();

            if(typeof getters.getConfig === 'function') {
                config = getters.getConfig;
            }

            if(keys.length > 0) {
                minPreloadDate.setDate(minPreloadDate.getDate() - (config.setup?.game["max_archive_days"] ?? 0) - 1);
                minPreloadDate.setUTCHours(0,0,0,0);

                await keys.forEach((nr,index) => {
                    /**
                     * Puzzle werden gelöscht wenn:
                     * Das Spiel veraltet ist (today - GAME_MAX_ARCHIVE_DAYS - 2 days)
                     */
                    if(new Date(values[index].date) < minPreloadDate) {
                        del(parseInt(nr)).then(ignore => {});
                        return;
                    }
                    else if(!puzzles[values[index].date]) { // Datum noch nicht in IndexedDB
                        puzzles[values[index].date] = [];
                    }
                    puzzles[values[index].date][values[index].level] = {
                        nr: nr,
                        sudoku: values[index].sudoku,
                        level: values[index].level,
                        date: values[index].date
                    };
                });

                commit("setPuzzles",puzzles);
            }
        }
    },
    mutations: {
        /**
         *
         * @param state
         * @param game
         */
        setCurrentGame: (state,game) => {
            state.game.archive = Boolean(game.archive);
            state.game.date = game.date;
            state.game.end = Boolean(game.end);
            state.game.errors = parseInt(game.errors);
            state.game.level = game.level;
            state.game.nr = parseInt(game.nr);
            state.game.sudoku = game.sudoku;
            state.game.score = game.score;
            state.game.time = game.time;
        },
        /**
         *
         * @param state
         * @param row
         * @param col
         */
        setCurrentFocus: (state,row,col = 0) => {
            state.game.focus.row = parseInt(row);
            state.game.focus.col = Number(col);
        },
        /**
         *
         * @param state
         * @param date
         * @param level
         * @param nr
         * @param errors
         * @param sudoku
         * @param end
         * @param score
         * @param setup
         * @param time
         */
        setGame: (state,{
            date, level, end, errors, nr, score, setup, sudoku = {}, time = 0
        }) => {
            state.games[date] = state.games[date] ?? {};
            state.games[date][level] = {
                date: date,
                end: Boolean(end),
                errors: parseInt(errors),
                level: level,
                nr: parseInt(nr),
                sudoku: sudoku,
                score: score,
                setup: setup,
                time: time
            };
        },
        /**
         * Sorts games to achieve the same structure of database and localStorage
         * @param state
         */
        sortGames: (state) => {
            state.games = Object.keys(state.games)
                .sort()
                .reduce((accumulator,key) => {
                    accumulator[key] = state.games[key];
                    return accumulator;
                },{});
        },
        /**
         *
         * @param state
         */
        resetGames: (state) => {
            state.games = {};
        },
        /**
         *
         * @param state
         * @param date
         * @param puzzle
         */
        setPuzzle: (state,{date,puzzle}) => {
            if(!state.puzzles[date]) {
                state.puzzles[date] = [];
            }
            state.puzzles[date][puzzle.level] = {
                nr: puzzle.nr,
                sudoku: puzzle.sudoku,
                level: puzzle.level,
                date: date
            };
        },
        /**
         *
         * @param state
         * @param puzzles
         */
        setPuzzles: (state,puzzles) => {
            state.puzzles = puzzles;
        }
    },
    getters: {
        /**
         * Get a game of the user by date and level
         * @param {*} state
         * @param {*} getters
         * @returns
         */
        getGameById: (state,getters) => (level,date = getters.today) => {
            if(state.games[date] !== undefined && state.games[date][level] !== undefined) {
                return state.games[date][level];
            }

            return null;
        },
        /**
         * Get all games of the user
         * @param state
         * @returns {{}|null}
         */
        getAllGames: state => {
            if(Object.keys(state.games).length > 0) {
                return state.games;
            }

            return null;
        },
        /**
         * Get all games of the user by date
         * @param state
         * @returns {(function(*): (*|null))|*}
         */
        getGamesByDate: (state) => (date) => {
            if(state.games[date] !== undefined) {
                return state.games[date];
            }

            return null;
        },
        /**
         *
         * @param state
         * @returns {(function(*): (number|number))|*}
         */
        getCountGamesByDate: state => date => {
            if(state.games.hasOwnProperty(date)) {
                return Object.values(state.games[date]).filter(game => game.end === true).length;
            }

            return 0;
        },
        /**
         *
         * @param state
         * @returns {number}
         */
        countAllDays: state => {
            const toArr = Object.getOwnPropertyNames(state.puzzles);
            return toArr.length;
        },
        /**
         *
         * @param state
         * @param getters
         * @returns {number}
         */
        countAllPuzzles: (state,getters) => {
            if(typeof getters.getConfig === "function") {
                config = getters.getConfig;
            }

            return getters.countAllDays * config.setup.game.games;
        },
        /**
         *
         * @param state
         * @param getters
         * @returns {(function(*=): (*|undefined))|*}
         */
        getAllPuzzlesByDate: (state,getters) => (date = getters.today) => {
            if(typeof getters.getConfig === "function") {
                config = getters.getConfig;
            }

            if(Object.keys(state.puzzles).length > 0 && state.puzzles[date] && Object.keys(state.puzzles[date]).length === config.setup.game.games) {
                return state.puzzles[date];
            }

            return null;
        },
        /**
         *
         * @param state
         * @param getters
         * @returns {any}
         */
        getPuzzleById: (state,getters) => (level,date = getters.today) => {
            if(Object.keys(state.puzzles).length > 0 && state.puzzles[date] !== undefined && state.puzzles[date][level] !== undefined) {
                return state.puzzles[date][level];
            }

            return null;
        },
    }
}
