import { createApp } from "vue"
import { config } from "./config"

import App from "./App.vue"
import axios from "axios"
import router from "./router"
import VueProgressBar from "@aacassandra/vue3-progressbar"
import Connector from "./vendor/publisher/connector"
import { createHead } from "@unhead/vue"
import { createStore } from 'vuex'
import { game } from './store/game'
import { user } from './store/user'
import { subscription } from "./store/subscription"
import Helper from "./mixins/helper.mix"
import wb from "./vendor/publisher/registerServiceWorker"
import Addon from "./vendor/publisher/sudoku"
import AdSlot from "./vendor/publisher/components/AdSlot.vue"

const app = createApp({
    components: {
        "App": App,
        "AdSlot": AdSlot
    }
});

const store = createStore({
    modules: {
        game,
        user,
        subscription
    },
    state: () => ({
        today: function () {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0');
            const yyyy = today.getFullYear();

            return yyyy + '-' + mm + '-' + dd;
        }()
    }),
    mutations: {
        setToday: (state) => {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0');
            const yyyy = today.getFullYear();

            state.today = yyyy + '-' + mm + '-' + dd;
        }
    },
    getters: {
        /**
         *
         * @returns {string}
         */
        today: (state) => {
            return state.today;
        }
    }
});

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$workbox = wb;
app.config.globalProperties.$store = store;
app.config.globalProperties.$config = config;

app.use(Connector,{ router, store, config })
app.use(Addon,{ router, store })
app.use(router);
app.use(createHead());
app.use(store);
app.use(VueProgressBar,{
    color: 'rgb(4 108 78)',
    failedColor: 'rgb(200 30 30)',
    height: "2px",
    autoFinish: false
});
app.mixin(Helper);
app.mount("#app");

require("./bootstrap");
