<template>
    <Puzzle :key="date + '-' + level" :level="level" :date="date"></Puzzle>
</template>

<script>
import Puzzle from "../components/Puzzle"
import { inject } from "vue"

export default {
    name: "Home",
    props: ["level"],
    components: {
        Puzzle,
    },
    computed: {
        /**
         *
         * @returns {*}
         */
        date() {
            return this.$route["query"]?.date || this.$store.getters.today;
        },
        /**
         *
         * @returns {*}
         */
        /*auth() {
            return this.$store.getters['auth/isAuth'];
        },*/
    },
    async beforeRouteEnter(to) {
        const archive = inject("archive");

        /**
         * 1. Prüfen, ob das Archiv angesteuert wird
         *      > archive.called(to.query.date)
         * 2. Prüfen, ob das Archiv aktiv ist, aber die Eingaben invalid - dann Redirect zu Archiv
         *      > archive.enabled()
         * 3. Archiv ist nicht aktiv, dann Redirect auf Home
         */

        if (archive.called(to.query.date)) {
            const enabled = archive.enabled();

            if (enabled && (!archive.inDateRange(archive.validate(to.query.date)))) { // Date is not valid
                store.dispatch("showToast", {
                    msg: "Das Spiel steht leider nicht zur Verfügung.",
                    type: "warn"
                }).finally(() => {
                    router.push({
                        name: "Archive"
                    })
                });
            } else if (enabled && !archive.active()) { // already checked if archive.called() === true
                await store.dispatch("auth/checkAuth")
                    .then(result => {
                        if (result !== true) {
                            router.push({
                                name: "Login",
                                query: {
                                    next: to.path + location.search
                                }
                            })
                        }
                    })
            } else if(!enabled) {
                router.push({
                    name: "Home"
                })
            }
        }
    }
}
</script>
