<template>
    <div v-show="show" :class="paywall ? 'bg-gray-500' : 'bg-white dark:bg-gray-900'" class="inline-flex justify-center items-center border-slate-500 dark:border-slate-100 absolute z-20 !bg-opacity-50 h-full w-full top-0 left-0">
        <!-- Paywall // Todo: move to global action in publisher-package -->
        <div v-if="paywall" class="relative bg-white p-7 rounded-lg w-full mx-5 md:max-w-md md:m-0">
            <div class="absolute right-5 top-5 pt-2.5" v-if="paywallLayer.icn">
                <SVG :svg="paywallLayer.icn" :classes="['w-7','h-7','fill-current']"></SVG>
            </div>
            <div class="mb-5" v-html="paywallLayer.text"></div>
            <a :href="paywallLayer.url" class="btn block btn-primary mb-5">{{ paywallLayer.cta }}</a>
            <p class="text-center text-sm text-gray-700">oder <a :href="paywallLayer.login_url" @click="$handleLogin" class="link-intern">anmelden</a></p>
        </div>
        <button v-else-if="!locked" @click="toggleTimer" class="group btn-primary inline-flex items-center">
            Spiel pausiert
            <icon icon="play" classes="w-10 h-10 group-hover:text-brand-500 text-brand-contrast ml-2 -mr-2"></icon>
        </button>
        <div v-else class="flex items-center btn btn-disabled text-left text-white">
            <icon icon="lock-closed" classes="w-8 h-8 mr-2"></icon>
            <div>
                <span class="block text-base">Sudoku: <strong>{{ levels[game.level] }}</strong></span>
                <em class="not-italic">Schwierigkeitsstufe nicht freigeschaltet</em>
            </div>
        </div>
    </div>
</template>

<script>

import SVG from "../vendor/publisher/components/SVG.vue";
import Icon from "../vendor/publisher/components/Icon";

export default {
    name: "Overlay",
    components: {
        Icon,
        SVG
    },
    emits: ["toggle"],
    props: ["show","locked","game","paywall"],
    data() {
        return {
            levels: this.$config.setup.game.levels,
            paywallLayer: { // Todo: move to global action in publisher-package
                text: this.$config.paywall.enabled ? this.markdownToHtml(this.$config.paywall.text) : null,
                cta: this.$config.paywall.cta,
                url: this.getSubscribeUrl(),
                icn: this.$config.paywall.icon,
                login_url: this.getSSOLoginUrl()
            }
        }
    },
    methods: {
        toggleTimer() {
            this.$emit("toggle");
        },
        /**
         *
         * @param e
         */
        handleLogin(e) { // Todo: move to connector -> Paywall component
            if(!this.$store.getters["auth/isAuth"] && this.$config.sso.custom && this.$config.sso.iframe) {
                e.preventDefault();
                window.dispatchEvent(new CustomEvent("login:modal"));
            }
            else if(!this.$store.getters["auth/isAuth"] && !this.$config.sso.custom) {
                e.preventDefault();
                router.push({name: "Login"});
            }
        }
    }
}
</script>
