<template>
    <nav class="flex px-4 py-2.5 text-gray-700 border-b border-gray-200 bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
        <div class="w-full @sm:max-w-4xl @sm:mx-auto @sm:px-4 flex items-center">
            <h1 class="inline-flex items-center">
                <em class="text-sm text-gray-700 dark:text-gray-400 font-medium not-italic">Sudoku</em>
                <icon icon="chevron-right" classes="w-6 h-6 text-gray-400 mx-1"></icon>
                <button aria-label="Schwierigkeitsgrad auswählen" type="button" id="dropdownLevelButton" class="text-brand-contrast transition-colors cursor-pointer bg-brand-500 hover:opacity-[.85] focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-full text-sm px-4 py-1 text-center inline-flex items-center">
                    {{ $config.setup.game.levels[game.level] }}
                    <icon icon="chevron-down" classes="ml-2 w-4 h-4"></icon>
                </button>
            </h1>
            <div id="dropdownLevel" class="no-flex hidden z-30 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
                <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownLevelButton">
                    <li v-for="(level,route) in levels">
                        <router-link @click="dropdownLevel.hide()" :to="{name:'Home',params:route !== Object.keys($config.setup.game.levels)[0] ? {level:level.toLowerCase()} : null}" :class="game.level === route ? 'bg-gray-100 dark:bg-gray-600 dark:hover:bg-gray-600' : null" class="flex items-center p-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                            <SVG v-if="$store.getters['subscription/isPaywall']('levels',route) && paywall.icon" :svg="paywall.icon" :classes="['w-4','h-4','mr-2','fill-current']"></SVG>
                            <template v-else>
                                <icon v-if="$store.getters.isLevelLocked(route)" icon="lock-closed" classes="w-4 h-4 text-gray-700 dark:text-gray-200 mr-2"></icon>
                                <icon v-else-if="!$store.getters.isLevelReady(route)" icon="play" classes="w-4 h-4 text-gray-700 dark:text-gray-200 mr-2"></icon>
                                <icon v-else icon="check" classes="w-4 h-4 text-gray-700 dark:text-gray-200 mr-2"></icon>
                            </template>
                            {{ level }}
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="grow text-right mr-2">
                <span class="text-sm text-gray-700 dark:text-gray-400 inline-flex items-center">Fehler:
                    <template v-if="help || game.end">{{ game.errors }}</template>
                    <icon v-else icon="eye-slash" classes="w-4 h-4 text-gray-700 dark:text-gray-400 ml-1"></icon>
                </span>
            </div>
            <button aria-label="Weitere Einstellungen öffnen" id="dropdownMenuButton" class="@md:hidden inline-flex items-center p-1 text-sm font-medium text-center text-gray-700 dark:text-gray-400 bg-transparent rounded-lg hover:bg-gray-200 focus:bg-transparent focus:ring-4 focus:ring-gray-300 focus:outline-none dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
                <icon icon="ellipsis-horizontal" classes="w-5 h-5"></icon>
            </button>
            <div id="dropdownMenu" class="z-30 hidden @md:!block @md:!transform-none @md:!relative @md:!inset-0 bg-white @md:bg-transparent divide-y divide-gray-100 rounded @md:rounded-none shadow @md:shadow-none w-44 @md:w-auto dark:bg-gray-700 dark:divide-gray-600 @md:dark:bg-gray-800">
                <ul class="py-1 @md:py-0 @md:flex @md:space-x-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuButton">
                    <li>
                        <button
                            @click="restartGame"
                            class="flex w-full @md:w-auto whitespace-nowrap @md:btn @md:btn-default p-2 @md:px-4 @md:py-1 items-center hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            :class="!allowedRestart() ? 'cursor-not-allowed !text-gray-400' : '@md:hover:bg-gray-200 @md:hover:dark:bg-gray-700 @md:focus:ring-4 @md:focus:ring-gray-200 @md:text-gray-700 @md:dark:text-gray-200 cursor-pointer'"
                            aria-label="Sudoku neu starten"
                            type="button">
                            <icon v-if="restart" icon="check" classes="w-4 h-4 mr-2"></icon>
                            <icon v-else icon="arrow-path" classes="w-4 h-4 mr-2"></icon>
                            Neustart
                        </button>
                    </li>
                    <li>
                        <button
                            @click="print"
                            class="flex w-full @md:w-auto whitespace-nowrap @md:btn @md:btn-default p-2 @md:px-4 @md:py-1 items-center hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            :class="!allowedPrinting() ? 'cursor-not-allowed !text-gray-400' : '@md:hover:bg-gray-200 @md:hover:dark:bg-gray-700 @md:focus:ring-4 @md:focus:ring-gray-200 @md:text-gray-700 @md:dark:text-gray-200 cursor-pointer'"
                            aria-label="Sudoku ausdrucken"
                            type="button">
                            <icon icon="printer" classes="w-4 h-4 mr-2"></icon>
                            Ausdrucken
                            <template v-if="!allowedPrinting() && printingPaywall">
                                <SVG v-if="paywall.icon" :svg="paywall.icon" :classes="['w-4','h-4','ml-1','fill-current']"></SVG>
                                <icon v-else icon="lock-closed" classes="w-4 h-4 text-gray-700 dark:text-gray-200 ml-1"></icon>
                            </template>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import { Dropdown } from "flowbite"
import SVG from "../vendor/publisher/components/SVG.vue";
import Icon from "../vendor/publisher/components/Icon";

export default {
    name: "Level",
    components: {
        Icon,
        SVG
    },
    props: ["game","help"],
    emits: ["reload","print","pause"],
    computed: {
        /**
         *
         * @returns {string}
         */
        renderHeadline: function () {
            return this.$route["name"] === "Home" && !this.$route["params"].level ? "h2" : "h1";
        },
        /**
         *
         * @returns {function(*): (boolean|*)}
         */
        levelPaywall: function () {
            return this.$store.getters["subscription/isPaywall"]("levels",this.game.level);
        },
        /**
         *
         * @returns {(function(*): boolean)|*}
         */
        printingPaywall: function () {
            return this.$store.getters["subscription/isPaywall"]("printing");
        }
    },
    data() {
        return {
            levels: this.$config.setup.game.levels,
            paywall: this.$config.paywall,
            restart: false,
            dropdownLevel: null,
            dropdownMenu: null,
            firstLevel: Object.keys(this.$config.setup.game.levels)[0],
        }
    },
    mounted() {
        let targetEl = document.getElementById("dropdownLevel");
        let triggerEl = document.getElementById("dropdownLevelButton");

        const options = {
            placement: "bottom",
        };

        this.dropdownLevel = new Dropdown(targetEl, triggerEl, options);

        targetEl = document.getElementById("dropdownMenu");
        triggerEl = document.getElementById("dropdownMenuButton");

        this.dropdownMenu = new Dropdown(targetEl, triggerEl, options);
    },
    methods: {
        async restartGame() {
            if (this.allowedRestart()) {
                this.$emit("pause");
                setTimeout(() => {
                    if (window.confirm(this.$config.content.messages.restart)) {
                        this.$emit("reload");
                        this.restart = true;
                        setTimeout(() => this.restart = false, 3000)
                    }
                }, 10)

                this.dropdownMenu.hide();
            }
        },
        /**
         *
         * @param e
         */
        print(e) {
            if(this.allowedPrinting()) {
                this.$emit("print");
                this.dropdownMenu.hide();
            }
            else if(this.printingPaywall) {
                this.$handleLogin(e);
            }
        },
        /**
         *
         * Drucken ist erlaubt wenn,
         *  - Das Level nicht gesperrt ist
         *  - oder this.$config.setup.game.unlock_printing === false
         *  - Die Paywall für das Level nicht aktiv ist & und die Print-Paywall nicht aktiv ist
         *
         * @returns {boolean}
         */
        allowedPrinting() {
            return (!this.game.locked || this.$config.setup.game["unlock_printing"] === false) && !this.levelPaywall && !this.printingPaywall;
        },
        /**
         *
         * @returns {boolean}
         */
        allowedRestart() {
            return !this.game.locked && !this.levelPaywall && this.game.end === false;
        }
    }
}
</script>
