import { config } from "../config"

export const user = {
    state: () => ({
        localStorageOrDataExists: false,
        canShare: window.navigator && window.navigator.share !== undefined,
        stats: {
            games: 0,
            time: 0,
            errors: 0,
            levels: Object.keys(config.setup.game.levels).reduce((accumulator, key) => {
                return {...accumulator, [key]: {
                    games: 0,
                    time: 0,
                    errors: 0
                }};
            },null),
        },
        levels: Object.keys(config.setup.game.levels).reduce((accumulator, key,i) => {
            return {...accumulator, [key]: {
                    locked: i !== 0,
                    end: false
                }};
        },null),
        setup: {
            darkMode: false,
            contrast: false,
            handwritten: true,
            helpMode: true
        },
    }),
    actions: {
        /**
         *
         * @param commit
         * @param dispatch
         * @param getters
         * @returns {Promise<unknown>}
         */
        setUser: ({commit,dispatch,getters}) => {
            return new Promise(async (resolve) => {
                await dispatch("migrateOldLocalStorage","_sdk_smart");

                if (typeof getters.getConfig === "function") {
                    config = getters.getConfig;
                }

                /**
                 * Es wird sich ausschließlich aus dem localStorage bedient.
                 * Nur bei der Registrierung, beim Login und beim Abschluss eines Spiels gibt es eine Synchronisierung mit der DB
                 */
                const data = {
                    stats: JSON.parse(localStorage.getItem(config.identifier + "-user-stats")) || {},
                    games: JSON.parse(localStorage.getItem(config.identifier + "-user-games")) || {}
                };

                if (Object.keys(data.stats).length > 0 || Object.keys(data.games).length > 0) {
                    /**
                     * setExistingData === true verhindert,
                     * dass der Init-Layer erneut angezeigt wird.
                     */
                    commit("setExistingData", true);

                    /**
                     * Die Daten werden explizit gesetzt,
                     * um zu verhindern, dass die Daten manipuliert werden.
                     */
                    commit("setStats", {
                        games: data.stats.games,
                        time: data.stats.time,
                        errors: data.stats.errors,
                        levels: data.stats.levels,
                    });

                    dispatch("setGames", data.games);
                    commit("sortGames");
                }
                /**
                 * No data exist; init localStorage.
                 */
                else {
                    dispatch("writeData");
                }

                /**
                 * Die Einstellungen können jederzeit abgefragt und gespeichert werden
                 */
                commit("setSetup", {
                    key: "darkMode",
                    _class: "dark",
                    status: JSON.parse(localStorage.getItem(config.identifier + "-pref-" + "darkMode".toLowerCase())) ?? getters.isDarkMode
                });
                commit("setSetup", {
                    key: "contrast",
                    _class: "contrast",
                    status: JSON.parse(localStorage.getItem(config.identifier + "-pref-" + "contrast".toLowerCase())) ?? getters.isContrast
                });
                commit("setSetup", {
                    key: "handwritten",
                    _class: "handwritten",
                    status: JSON.parse(localStorage.getItem(config.identifier + "-pref-" + "handwritten".toLowerCase())) ?? getters.isHandwritten
                });
                commit("setSetup", {
                    key: "helpMode",
                    _class: null,
                    status: JSON.parse(localStorage.getItem(config.identifier + "-pref-" + "helpMode".toLowerCase())) ?? getters.isHelpMode
                });

                dispatch("setLevels");

                commit("auth/setSynced", localStorage.getItem(config.identifier + "-user-synced") !== "false");

                resolve(true);
            });
        },
        /**
         * TODO: Remove after 1 year (2024-07-14) from launch (2023-07-15) this script
         *
         * @param commit
         * @param dispatch
         * @param getters
         * @param key
         * @returns {Promise<void>}
         */
        migrateOldLocalStorage: async ({commit,dispatch,getters},key) => {
            const items = [
                "user-games",
                "user-stats",
                "pref-handwritten",
                "pref-darkmode"
            ];

            for (const item of items) {
                const value = localStorage.getItem(key + "-" + item);
                if(value) {
                    await dispatch("writeToLocalStorage",{
                        key: item,
                        val: value
                    });
                }
                localStorage.removeItem(key + "-" + item);
            }
        },
        /**
         *
         * @param commit
         * @param getters
         * @returns {Promise<unknown>}
         */
        setLevels: ({commit,getters}) => {
            return new Promise((resolve) => {
                const levels = Object.keys(getters.getLevels);
                const stats = getters.getStats;

                for (const level of levels) {
                    const index = levels.indexOf(level);
                    const { games } = stats.levels[levels[index <= 0 ? 0 : index - 1]];

                    commit("setLevel", {
                        level: level,
                        key: "locked",
                        val: (index !== 0 && games < config.setup.game.success_to_unlock)
                    });
                }

                resolve(true);
            });
        },
        /**
         *
         * @param commit
         * @param dispatch
         * @param getters
         * @param sync
         * @returns {Promise<void>}
         */
        writeData: async ({dispatch,getters},sync = false) => {
            const stats = JSON.stringify(getters.getStats || {}),
                games = JSON.stringify(getters.getAllGames || {});

            if(sync === true) {
                dispatch("auth/sync",{
                    stats: JSON.parse(stats),
                    games: JSON.parse(games)
                });
            }

            await dispatch("writeToLocalStorage",{
                key: "user-stats",
                val: stats
            });
            await dispatch("writeToLocalStorage",{
                key: "user-games",
                val: games
            });
        },
        /**
         *
         * @param {*} param0
         * @param {*} param1
         */
        writeToLocalStorage: ({commit},{key,val}) => {
            localStorage.setItem(config.identifier + "-" + key,val);
        },
    },
    mutations: {
        /**
         *
         * @param state
         * @param games
         * @param time
         * @param errors
         * @param levels
         */
        setStats: (state,{
            games,time,errors,levels
        }) => {
            state.stats.games = parseInt(games) || 0;
            state.stats.time = parseInt(time) || 0;
            state.stats.errors = parseInt(errors) || 0;
            state.stats.levels = levels || Object.keys(config.setup.game.levels).reduce((accumulator, key) => {
                return {...accumulator, [key]: {
                        games: 0,
                        time: 0,
                        errors: 0
                    }};
            },null);
        },
        /**
         *
         * @param state
         * @param status
         */
        setExistingData: (state,status) => {
            state.localStorageOrDataExists = status;
        },
        /**
         *
         * @param state
         * @param key
         * @param _class
         * @param status
         */
        setSetup: (state,{key,_class,status = false}) => {
            state.setup[key] = status;

            if(status && _class) {
                document.querySelector("html").classList.add(_class);
            }
            else if (_class) {
                document.querySelector("html").classList.remove(_class);
            }
        },
        /**
         *
         * @param state
         * @param level
         * @param key
         * @param val
         */
        setLevel: (state,{level,key,val}) => {
            state.levels[level][key] = val;
        }
    },
    getters: {
        /**
         *
         * @param state
         * @returns {boolean}
         */
        isDarkMode: state => {
            return state.setup.darkMode;
        },
        /**
         *
         * @param state
         * @returns {boolean}
         */
        isContrast: state => {
            return state.setup.contrast;
        },
        /**
         *
         * @param state
         * @returns {boolean}
         */
        isHandwritten: state => {
            return state.setup.handwritten;
        },
        /**
         *
         * @param state
         * @returns {boolean}
         */
        isHelpMode: state => {
            return state.setup.helpMode;
        },
        /**
         *
         * @param state
         * @returns {*}
         */
        canShare: state => {
            return state.canShare;
        },
        /**
         *
         * @param state
         * @returns {function(*): boolean|*}
         */
        isLevelLocked: state => level => {
            if(state.levels.hasOwnProperty(level)) {
                const { locked } = state.levels[level];

                return locked;
            }

            return true;
        },
        /**
         *
         * @param state
         * @returns {function(*): *}
         */
        isLevelReady: state => level => {
            const { end } = state.levels[level];

            return end;
        },
        /**
         *
         * @param state
         * @returns {function(*): boolean}
         */
        isLastLevel: state => (level) => {
            return Object.keys(state.levels).findIndex(key => key === level) === (state.levels.length-1);
        },
        /**
         *
         * @param state
         * @returns {*}
         */
        getStats: state => {
            return state.stats;
        },
        /**
         *
         * @param state
         * @returns {(function(*): (*|null))|*}
         */
        getStat: state => (key) =>  {
            if(state.stats.hasOwnProperty(key)) {
                return state.stats[key];
            }

            return null;
        },
        /**
         *
         * @param state
         * @returns {(function(*, *): (*|undefined))|*}
         */
        getNestedStat: state => (level,key) => {
            if(state.stats.levels[level].hasOwnProperty(key)) {
                return state.stats.levels[level][key];
            }
        },
        /**
         *
         * @param state
         * @returns {string}
         */
        getLevels: state => {
            return state.levels;
        },
        /**
         *
         * @param state
         * @returns {(function(*): (*|null))|*}
         */
        getLevel: state => level => {
            if(state.levels.hasOwnProperty(level)) {
                return state.levels[level];
            }

            return null;
        },
        /**
         *
         * @param {*} state
         * @returns
         */
        getNextLevel: (state) => (currentLevel,add = 1) => {
            const levels = Object.keys(state.levels), i = levels.indexOf(currentLevel);

            return levels[i+add] || levels[0];
        },
        /**
         * - Prüfe, ob das nächst höhere Level in der Reihe freigeschaltet ist
         * - und ob es gespielt wurde oder nicht.
         * - Starte diesen Prozess von "aktuelles Level" bis "Experte"
         * - Wenn kein Level bleibt, dann schaue, ob es von "aktuelles Level" bis "Leicht" ein Spiel gibt
         *
         * @param state
         * @returns {function(*): string}
         */
        getNextLevelToPlay: state => currentLevel => {
            const indexCurrentLevel = Object.keys(state.levels).findIndex(key => key === currentLevel) || 0;
            let nextLevel = Object.keys(state.levels).filter((level,key) => key > indexCurrentLevel).find(level => state.levels[level].end !== true && state.levels[level].locked === false);

            if(!nextLevel) {
                nextLevel = Object.keys(state.levels).find(level => state.levels[level].end !== true && state.levels[level].locked === false)
            }

            return nextLevel;
        },
        /**
         *
         * @param state
         * @returns {string|boolean}
         */
        getNextLevelToUnlock: state => {
            return Object.keys(state.levels).find(level => state.levels[level].locked === true && state.levels[level].end === false) ?? false;
        },
        /**
         *
         * @param state
         * @returns {number}
         */
        getCountUnlockedLevels: state => {
            return Object.keys(state.levels).filter(level => state.levels[level].locked === false).length;
        },
        /**
         *
         * @param state
         * @returns {number}
         */
        getRemainingGames: state =>  {
            return Object.keys(state.levels).filter(level => state.levels[level].locked === false && state.levels[level].end === false).length;
        },
    }
}
