<template>
    <div class="static @container">
        <ol v-if="fetchData" class="relative border-l border-gray-200 dark:border-gray-700" data-accordion="collapse" data-active-classes="bg-gray-50 dark:bg-gray-700 rounded-b-none active" data-inactive-classes="bg-white dark:bg-gray-800 inactive">
            <li class="mb-10 ml-4" v-for="day in entries">
                <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{{ day.dateToString }}</time>
                <ol class="w-full sm:w-96 text-sm font-medium text-gray-900 border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-600">
                    <li class="w-full" v-for="(game,index) in day.games">
                        <button :data-accordion-target="'#accordion-collapse-body-' + day.date + '-' + game.nr" :aria-controls="'accordion-collapse-body-' + day.date + '-' + game.nr" v-bind:class="className(index)" class="group inactive w-full px-4 py-2 flex cursor-pointer hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-700" v-bind:id="'accordion-collapse-heading-' + day.date + '-' + game.nr">
                            <icon v-if="game.user && game.user.end" icon="check-badge" type="solid" classes="h-5 w-5 mr-2 text-green-700 dark:text-green-400 contrast:!text-orange-500"></icon>
                            <icon v-else-if="$store.getters.isLevelLocked(game.level)" icon="lock-closed" classes="h-5 w-5 mr-2 text-slate-600 dark:text-slate-300 contrast:!text-sky-500"></icon>
                            <icon v-else-if="game.user && game.user.score[0].length > 0" icon="pause-circle" classes="h-5 w-5 mr-2 text-slate-600 dark:text-slate-300 contrast:!text-sky-500"></icon>
                            <icon v-else icon="exclamation-circle" type="solid" classes="h-5 w-5 mr-2 text-slate-600 dark:text-slate-300 contrast:!text-sky-500"></icon>
                            <span class="text-left grow text-gray-500 dark:text-gray-300">#{{ game.nr }} {{ levels[game.level] }}</span>
                            <!-- Arrow down -->
                            <icon icon="chevron-down" classes="group-[.active]:hidden h-5 w-5 shrink-0 text-gray-500 dark:text-gray-300"></icon>
                            <!-- /Arrow down -->
                            <!-- Arrow up -->
                            <icon icon="chevron-up" classes="group-[.inactive]:hidden h-5 w-5 shrink-0 text-gray-500 dark:text-gray-300"></icon>
                            <!-- /Arrow up -->
                        </button>
                        <div v-bind:id="'accordion-collapse-body-' + day.date + '-' + game.nr" class="hidden">
                            <div class="p-5 border-gray-200 dark:border-gray-600" v-bind:class="index !== Object.keys(day.games).length-1 ? 'border-b' : 'border-t'">
                                <p class="text-gray-500 dark:text-gray-300" v-if="game.user.end">
                                    <strong>Sudoku #{{ game.nr }} {{ levels[game.level] }}</strong> {{ $addressingUser('hast du','haben Sie') }} erfolgreich gelöst.</p>
                                <p class="text-gray-500 dark:text-gray-300" v-else-if="$store.getters.isLevelLocked(game.level)">
                                    <strong>Sudoku #{{ game.nr }} {{ levels[game.level] }}</strong> ist noch nicht freigeschaltet. Löse{{ $addressingUser('','n Sie') }} {{ $config.setup.game.success_to_unlock }} Sudokus auf der Stufe "{{ levels[$store.getters.getNextLevel(game.level,-1)] }}", um "{{ levels[game.level] }}" freizuschalten.
                                </p>
                                <p class="text-gray-500 dark:text-gray-300" v-else-if="game.user && game.user.end === false && game.user.score[0].length > 0">
                                    <strong>Sudoku #{{ game.nr }} {{ levels[game.level] }}</strong> {{ $addressingUser('hast du','haben Sie') }} angefangen aber nicht zu Ende gespielt. Spiele{{ $addressingUser('','n Sie') }} jetzt weiter.
                                </p>
                                <p class="text-gray-500 dark:text-gray-300" v-else-if="!game.user || game.user.score[0].length === 0">
                                    <strong>Sudoku #{{ game.nr }} {{ levels[game.level] }}</strong> {{ $addressingUser('hast du','haben Sie') }} noch nicht gespielt.{{ today !== day.date ? (" " + $addressingUser('Du kannst','Sie können') + ' dies jetzt nachholen.') : '' }}
                                </p>
                                <div v-if="!$store.getters.isLevelLocked(game.level)" class="grid gap-3 grid-cols-1 @md:grid-cols-2 mt-3">
                                    <div class="flex items-center">
                                        <span class="mr-2 inline-flex items-center">
                                            <icon icon="clock" classes="w-5 h-5 mr-0.5 text-gray-700 dark:text-gray-300"></icon>
                                            <i class="not-italic text-xs text text-gray-700 dark:text-gray-300">{{ getReadableTime(game.user.time) }}</i>
                                        </span>
                                        <span class="inline-flex items-center">
                                            <icon icon="exclamation-circle" classes="w-5 h-5 mr-0.5 text-gray-700 dark:text-gray-300"></icon>
                                            <i class="not-italic text-xs text-gray-700 dark:text-gray-300">{{ game.user.errors ?? 0 }} Fehler</i>
                                        </span>
                                    </div>
                                    <router-link :to="routeArchive(game.level,day.date)" custom v-slot="{ navigate }">
                                        <button @click="navigate" class="btn-default btn-medium inline-flex items-center justify-self-stretch">
                                            {{ !game.user.end ? (!game.user || game.user.score[0].length === 0 ? 'Spielen' : 'Weiterspielen') : 'Ergebnis anzeigen' }}
                                            <icon icon="chevron-right" classes="h-4 w-4 ml-1 -mr-1"></icon>
                                        </button>
                                    </router-link>
                                    <template v-if="game.user.end">
                                        <div class="whitespace-pre-line tracking-share text-center @md:text-left">
                                            {{ game.user.result }}
                                        </div>
                                        <div class="justify-self-stretch">
                                            <button class="btn btn-default btn-medium w-full btn-default inline-flex items-center" @click="copyToClipboard(buildShareText(game.nr,game.level,game.user.time,game.user.errors,game.user.sudoku))">
                                                <icon icon="document-duplicate" classes="h-4 w-4 mr-1 -ml-1"></icon>
                                                Ergebnis kopieren
                                            </button>
                                            <button class="btn btn-default btn-medium w-full btn-default inline-flex items-center mt-3 flex-none" v-if="$store.getters.canShare" @click="shareResult(game.nr,game.level,game.user.time,game.user.errors,game.user.sudoku)">
                                                <icon icon="share" classes="h-4 w-4 mr-1 -ml-1"></icon>
                                                Ergebnis teilen
                                            </button>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </li>
                </ol>
            </li>
        </ol>
        <Spinner v-else classes="absolute left-1/2 top-30 -ml-4 w-8 h-8 contrast:fill-orange-500"></Spinner>
    </div>
</template>
<script>
import Share from "@mixins/share.mix.js"
import Icon from "../components/Icon"
import { initAccordions } from "flowbite"
import Spinner from "../components/Spinner.vue"

export default {
    name: "Archive",
    components: {
        Spinner,
        Icon
    },
    mixins: [
        Share
    ],
    data() {
        return {
            levels: this.$config.setup.game.levels,
            fetchData: false,
            countDays: 1,
            entries: [],
            lastGameNr: null,
            accordionItems: [],
        }
    },
    computed: {
        puzzles: function() {
            return this.$store.getters.getAllPuzzlesByDate(this.today);
        },
        today: function() {
            return this.$store.getters.today;
        }
    },
    created() {
        this.countDays += this.$config.setup.game.max_archive_days;
    },
    mounted() {
        new Promise((resolve, reject) => {
            this.$store.dispatch("getGames")
                .then(() => {
                    resolve(this.fetchData = true)
                })
                .catch(error => reject(error));
        })
            .then(async () => {
                this.lastGameNr = this.puzzles[Object.keys(this.levels)[Object.keys(this.levels).length - 1]].nr;
                await this.setTimeline();
                initAccordions();
            })
            .finally(() => {
                this.fetchData = true;
            });
    },
    methods: {
        setTimeline() {
            const today = new Date(new Date(this.today).setHours(0,0,0,0));

            for(let i = 0;i < this.countDays;i++) {
                let date = new Date(new Date(this.today).setHours(0,0,0,0));
                date.setDate(today.getDate()-i);
                let dateKey = this.dateKey(date);
                const games = this.getGames(i,dateKey);

                if(games.length > 0) {
                    this.entries[i] = {
                        date: dateKey,
                        day: date.getDay(),
                        dateToString: this.dateToString(i,date),
                        games: games,
                    };
                }
            }
        },
        /**
         *
         * @param level
         * @param date
         * @returns {{query: {date: (*|null)}, name: string, params: {level: (string|string)}}}
         */
        routeArchive(level,date) {
            const query = {};

            if(this.today !== date) {
                query["date"] = date;
            }

            return {
                name: "Home",
                params: {
                    level: level !== Object.keys(this.$config.setup.game.levels)[0] ? this.levels[level].toLowerCase() : "",
                },
                query: query
            }
        },
        /**
         *
         * @param date
         * @returns {string}
         */
        dateKey(date) {
            const dd = String(date.getDate()).padStart(2, '0'),
                mm = String(date.getMonth() + 1).padStart(2, '0'),
                yyyy = date.getFullYear();

            return yyyy + '-' + mm + '-' + dd;
        },
        /**
         *
         * @param index
         * @param date
         * @returns {string}
         */
        dateToString(index,date) {
            let string = "";

            if(index === 0) {
                string+= "Heute";
            }
            else if(index === 1) {
                string+= "Gestern";
            }
            else {
                string+= date.toLocaleString("de-de",{
                    weekday: "long"
                });
            }

            string+= ", ";
            string+= String(date.getDate()).padStart(2,"0");
            string+= ". ";
            string+= date.toLocaleString("de-de",{
                month: "long"
            });

            return string;
        },
        /**
         *
         * @param index
         * @param date
         * @returns {*[]}
         */
        getGames(index,date) {
            let games = [];

            const levels = Object.keys(this.levels);

            if(index === 0) { // Today
                let i = 0;
                for(const level in this.puzzles) {
                    games[i] = {
                        nr: this.puzzles[level].nr,
                        level: level,
                        user: this.getUserGame(this.puzzles[level].nr,date),
                        index: index+i
                    }
                    i++;
                }
            }
            else {
                for(let i = 0;i<levels.length;i++) {
                    let nr = this.lastGameNr-levels.length-(index*levels.length)+(i+1);

                    if(nr > 0) {
                        games[i] = {
                            nr: nr,
                            level: levels[i],
                            user: this.getUserGame(nr,date),
                            index: (index*levels.length)+i
                        };
                    }
                }
            }

            return games;
        },
        /**
         *
         * @param nr
         * @param date
         * @returns {boolean}
         */
        getUserGame(nr,date) {
            const games = this.$store.getters.getGamesByDate(date);

            let userGame = false;

            if(games && Object.keys(games).length > 0) {
                Object.keys(games).forEach((level)=>{
                    if(games[level].nr === nr) {
                        userGame = {
                            end: games[level].end,
                            errors: games[level].errors,
                            score: games[level].score,
                            time: games[level].time,
                            result: this.buildEmojiSquares(games[level].sudoku),
                            sudoku: games[level].sudoku
                        };
                    }
                });

                return userGame;
            }

            return false;
        },
        /**
         *
         * @param index
         * @returns {string}
         */
        className(index = 0) {
            const length = Object.keys(this.levels).length;

            if(index === 0 && length > 1) {
                return 'border-b border-gray-200 dark:border-gray-600 rounded-t-lg';
            }
            else if(index === 0) {
                return 'rounded-lg';
            }
            else if(index < (length-1)) {
                return 'border-b border-gray-200 dark:border-gray-600';
            }
            else {
                return 'rounded-b-lg';
            }
        }
    },
    beforeRouteEnter() {
        if(!store.getters.getConfig.setup?.features?.archive || store.getters.getConfig.setup?.features?.archive !== true) {
            router.replace({
                name: "Home"
            });
        }
    }
}
</script>
