<template>
    <div class="relative px-4 w-full max-w-lg h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex justify-between items-center py-3 px-5 sm:p-5 rounded-t border-b dark:border-gray-600">
                <h3 class="text-lg inline-flex sm:text-lg font-medium text-gray-900 dark:text-white">
                    <icon icon="chart-bar" classes="h-6 w-6 mr-2 text-gray-600 dark:text-gray-500"></icon>
                    {{ $addressingUser('Deine','Ihre') }} Statistik
                </h3>
                <button @click="closeOverlay" aria-label="Fenster schließen" class="text-gray-500 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                    <icon icon="x-mark" classes="w-5 h-5"></icon>
                </button>
            </div>
            <!-- Modal tabs -->
            <div id="tabs-level" class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-600">
                <ul class="flex w-full flex-wrap -mb-px text-sm font-medium text-center" role="tablist">
                    <li class="flex-1" v-for="(level,route) in levels" role="presentation">
                        <button :id="'level-tab-' + route" type="button" role="tab" class="w-full block p-4 rounded-t-lg border-b-2">{{ level }}</button>
                    </li>
                </ul>
            </div>
            <!-- /Modal tabs -->
            <div :id="'level-container-' + level" v-for="(data,level) in stats" class="hidden">
                <level-stats :stats="data" :current-game="currentGame" :level="level"></level-stats>
                <!-- Modal footer -->
                <Actions @close="closeOverlay" @continue="continueGame" :current-game="currentGame" :level="level" :overlay="true"></Actions>
                <!-- /Modal footer -->
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute } from "vue-router"
import { useHead } from "@unhead/vue"
import Actions from "../components/Actions"
import { Tabs } from "flowbite"
import Icon from "../vendor/publisher/components/Icon"
import LevelStats from "../components/LevelStats.vue"

export default {
    name: "Stats",
    props: ["currentGame","timer"],
    emits: ["breakGame"],
    components: {
        LevelStats,
        Icon,
        Actions,
    },
    setup() {
        const route = useRoute();
        useHead({
            title: route.meta["title"]
        });
    },
    data() {
        return {
            stats: {},
            tabs: [],
            levels: this.$config.setup.game.levels,
        }
    },
    created() {
        this.breakGame();
        this.buildStats();
    },
    mounted() {
        document.body.addEventListener("click",this.clickOverlay,true);

        Object.keys(this.levels).forEach(key => {
            this.tabs.push({
                id: "tab-" + key,
                triggerEl: document.querySelector('#level-tab-' + key),
                targetEl: document.querySelector('#level-container-' + key)
            });
        });

        const options = {
            defaultTabId: "tab-" + this.currentGame.level,
            activeClasses: "text-brand-500 border-brand-500 active dark:text-brand-400 dark:border-brand-500",
            inactiveClasses: "text-gray-500 border-transparent dark:text-gray-400 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
        };

        new Tabs(document.getElementById("tabs-level"),this.tabs,options);
    },
    unmounted() {
        document.body.removeEventListener("click",this.clickOverlay,true);
    },
    methods: {
        /**
         *
         * @param elm
         */
        breakGame(elm) {
            if(!this.timer.pause) {
                this.$emit("breakGame", elm);
            }
        },
        /**
         *
         * @param elm
         */
        continueGame(elm) {
            if(this.timer.pause) {
                this.$emit("breakGame", elm);
            }
        },
        /**
         *
         * @param e
         */
        closeOverlay(e) {
            e.preventDefault();

            const route = {
                name: "Home",
                params: {
                    level: this.currentGame.level !== Object.keys(this.$config.setup.game.levels)[0] ? this.$config.setup.game.levels[this.currentGame.level].toLowerCase() : null
                }
            };

            if(this.currentGame.archive) {
                route["query"] = {
                    date: this.currentGame.date
                };
            }

            router.push(route);
        },
        /**
         *
         * @param e
         */
        clickOverlay(e) {
            const modal = document.querySelector("[aria-modal]:not([id=CybotCookiebotDialog])");
            if(modal && !e.target.closest('#' + modal.getAttribute("id") + ' .shadow')) {
                this.closeOverlay(e);
            }
        },
        /**
         *
         * @returns {Promise<unknown>}
         */
        buildStats() {
            const levels = this.$store.getters.getLevels;

            for(const level in levels) {
                const { games, time, errors } = this.$store.getters.getStat("levels")[level];

                this.stats[level] = {
                    totalGames: games,
                    totalTime: time,
                    totalErrors: errors,
                    avgTime: this.getAvgTime(time,games),
                    avgErrors: this.getAvgErrors(errors,games),
                    locked: levels[level].locked,
                }
            }
        }
    }
};
</script>
