<template>
    <div class="@container">
        <Level :key="date" @reload="restartGame" @pause="pauseTimer" @print="generatePdf" :game="game" :help="help"></Level>
        <div class="container max-w-4xl mx-auto">
            <div id="game">
                <!-- Game -->
                <div class="px-2 @sm:px-4 pt-2 @sm:pt-4 grid grid-cols-1 @sm:grid-cols-3 gap-0 @sm:gap-4">
                    <div id="game-grid" class="relative col-span-1 @sm:col-span-2 @sm:!max-h-full" v-bind:class="[{'grid-help':help}]">
                        <div id="game-square" class="grid grid-rows-9 grid-cols-9 aspect-square h-full mx-auto">
                            <template v-for="row in grid.length">
                                <div
                                    v-for="col in grid[row-1].column.length"
                                    v-on:click="clickCell(row-1,col-1)"
                                    :class="[{'cell-focus':grid[row-1].column[col-1].focus,'cell-duplicate':grid[row-1].column[col-1].duplicate},grid[row-1].column[col-1].class]"
                                    class="inline-flex justify-center items-center border-l border-t border-slate-300 dark:border-slate-500 select-none uppercase outline-none focus:outline-none">
                                    <div class="number" v-if="!overlay && grid[row-1].column[col-1].number !== ''">{{ grid[row-1].column[col-1].number }}</div>
                                    <div class="flex flex-wrap items-stretch h-full w-full" v-if="grid[row-1].column[col-1].empty" v-show="!overlay && grid[row-1].column[col-1].number === ''">
                                        <div class="cell-candidate text-center basis-1/3 align-bottom sq:leading-xs leading-none" :class="grid[row-1].column[col-1].candidates[c] !== true ? 'invisible' : null" v-for="c in $config.setup.game.range">
                                            <span class="relative -bottom-0.5 @md:-bottom-1">{{ c }}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <Overlay :paywall="paywall" :locked="locked" :show="overlay" :game="game" @toggle="toggleTimer"></Overlay>
                    </div>
                    <div id="game-keyboard" class="w-full px-2 @sm:px-0 pt-4 pb-2 @sm:pt-0 h-44 @sm:h-80 relative">
                        <div v-if="(!locked && !game.end && fetchedData) || showModal || paywall" class="keyboard grid grid-cols-5 @sm:grid-cols-3 auto-rows-fr gap-2 grid-flow-row mx-auto h-full">
                            <button v-on:click="simKeyboardEnter(keyCode,$event)" v-for="(keyCode,num) in [49,50,51,52,53,54,55,56,57]" v-bind:class="[{'key-candidate':game.setup.candidate},'key-row-' + Math.ceil((num + 1) / 3),'key-col-' + Math.ceil((num % 3)+1),{'key-inactive':help && numbers[num+1] >= $config.setup.game.rows}]" class="key key-number">{{ num+1 }}</button>
                            <button v-on:click="switchSetup('candidate')" v-bind:class="[{'key-setup-on':game.setup.candidate}]" class="key key-setup key-setup-candidate relative order-first @sm:order-none col-span-2 @sm:col-auto">
                                <icon icon="pencil-square" classes="w-5 h-5 mr-1 inline @sm:block @sm:mx-auto"></icon>
                                <span class="inline align-middle">Notizen</span>
                                <span class="badge">{{ game.setup.candidate ? "An" : "Aus" }}</span>
                            </button>
                            <div @click="toggleTimer" :title="(timer.pause ? 'Weiterspielen' : 'Pause') + ' (Leertaste)'" class="cursor-pointer inline-flex flex-wrap justify-center items-center order-first @sm:order-last col-start-3 @sm:col-start-2">
                                <time class="font-mono text-gray-700 dark:text-gray-400 mr-0.5">{{ minutes }}:{{ seconds }}</time>
                                <span class="toggle">
                                    <icon v-if="game.end" icon="check" classes="w-5 h-5 text-green-700"></icon>
                                    <icon v-else-if="!timer.pause" icon="pause" classes="w-5 h-5 text-brand-500"></icon>
                                    <icon v-else icon="play" classes="w-5 h-5 text-brand-500"></icon>
                                </span>
                            </div>
                            <button v-on:click="simKeyboardEnter(8,$event)" role="button" aria-label="Zahl löschen" class="key key-number">
                                <icon icon="backspace" classes="w-6 h-6 mx-auto"></icon>
                            </button>
                            <button v-on:click="switchSetup('helpMode')" v-bind:class="[{'key-setup-on':help}]" class="key key-setup key-setup-help relative col-span-2 col-start-4 order-first @sm:order-none @sm:col-start-auto @sm:col-auto">
                                <icon icon="light-bulb" classes="w-5 h-5 mr-1 inline @sm:block @sm:mx-auto"></icon>
                                <span class="inline align-middle">Hilfe</span>
                                <span class="badge">{{ help ? "An" : "Aus" }}</span>
                            </button>
                        </div>
                        <template v-else>
                            <Spinner v-if="!game.end && !locked" classes="absolute left-1/2 top-1/2 -ml-4 -mt-4 w-8 h-8 contrast:fill-orange-500"></Spinner>
                            <Actions v-else :current-game="game" :level="game.level" :overlay="false"></Actions>
                        </template>
                    </div>
                </div>
                <!-- /Game -->
            </div>
        </div>
    </div>
    <!-- Stats -->
    <div v-if="showModal" aria-modal="true" class="overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-40 justify-center items-center h-modal flex transition-all duration-300" id="stats">
        <router-view :current-game="game" :timer="timer" @breakGame="toggleTimer"></router-view>
    </div>
    <div v-if="showModal" :class="$integration() === 'dns' ? 'bg-gray-900 bg-opacity-50' : 'bg-white/30 backdrop-blur-sm'" class="fixed inset-0 z-30 transition-all"></div>
    <!-- /Stats -->
</template>

<script>
import { useHead } from "@unhead/vue"
import { getCurrentInstance, reactive } from "vue"
import { useRoute } from "vue-router/dist/vue-router"
import Actions from "../components/Actions"
import Level from "../components/Level"
import jsPDF from "jspdf"
import { applyPlugin } from "jspdf-autotable"
import Overlay from "./Overlay"
import Icon from "../vendor/publisher/components/Icon"
import Spinner from "../vendor/publisher/components/Spinner.vue";
applyPlugin(jsPDF);

export default {
    name: "Sudoku",
    components: {
        Spinner,
        Icon,
        Overlay,
        Actions,
        Level,
    },
    props: ["level","date"],
    setup(props) {
        const internalInstance = getCurrentInstance(), route = useRoute(), meta = reactive({
            title: internalInstance.appContext.config.globalProperties.$config.content.meta[props.level?.capitalize()]?.title,
            description: internalInstance.appContext.config.globalProperties.$config.content.meta[props.level?.capitalize()]?.description,
            canonical: window.location.protocol + "//" + window.location.hostname + (internalInstance.appContext.config.globalProperties.$config.content.meta[props.level?.capitalize()]?.canonical || route.path),
        });

        useHead({
            title: () => meta.title,
            meta: [
                {
                    name: "description",
                    content: () => meta.description,
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: () => meta.canonical,
                },
            ],
        });

        return {
            meta
        }
    },
    data: function() {
        return {
            game: {
                archive: !!(this.$route["query"]?.date),
                date: this.date,
                end: false,
                errors: 0,
                nr: 0,
                level: this.level,
                score: [],
                setup: {
                    candidate: false
                },
                solution: [],
                sudoku: [],
                time: 0
            },
            html: {
                header: 113, // 122, 130 -> old
                container: null,
                grid: null,
                square: null,
                keyboard: null,
                result: null,
                title: ""
            },
            showModal: this.$route["meta"]?.showModal ?? false,
            fetchedData: false,
            grid: [],
            rows: [...Array(this.$config.setup.game.rows)].map(ignore => []),
            cols: [...Array(this.$config.setup.game.cols)].map(ignore => []),
            blocks: [...Array(this.$config.setup.game.rows)].map(ignore => []),
            numbers: this.$config.setup.game.range.reduce((a, v) => ({ ...a, [v]: 0}),null),
            timer: {
                interval: null,
                total: 0,
                pause: false,
                countdown: {
                    interval: null,
                    timeUntilPause: 10 // Seconds
                },
                autoPause: true,
            },
            focus: {
                row: 0,
                col: 0
            }
        }
    },
    watch: {
        $route ({meta}) {
            this.showModal = meta.showModal;
        },
        'timer.pause' (pause) {
            if(pause) {
                this.stopTimer();
            }
            else {
                this.startTimer();
            }
        },
        'paywall' (status) {
            this.timer.pause = status;
        }
    },
    computed: {
        puzzles: function() {
            return this.$store.getters.getAllPuzzlesByDate(this.date);
        },
        minutes: function() {
            const minutes = Math.floor(this.timer.total / 60);
            return this.padTime(minutes);
        },
        seconds: function() {
            const seconds = this.timer.total - this.minutes * 60;
            return this.padTime(seconds);
        },
        overlay: function() {
            return this.locked === true || this.paywall || (this.timer.pause === true && this.game.end === false);
        },
        help: function() {
            return this.$store.getters.isHelpMode;
        },
        locked: function () {
            return this.$store.getters.isLevelLocked(this.game.level);
        },
        paywall: function () { // Todo: move to global action in publisher-package
            return this.$store.getters["subscription/isPaywall"]("levels",this.game.level);
        },
        today: function() {
            return this.$store.getters.today;
        }
    },
    created() {
        /**
         * Wenn this.$store.getters.getRemainingGames === 0 aber der Nutzer heute noch keine Spiele gespielt hat
         * > dispatch("setUser")
         */
        if(this.$store.getters.getCountGamesByDate(this.today) !== (this.$store.getters.getCountUnlockedLevels-this.$store.getters.getRemainingGames)) {
            this.$store.dispatch("setUser");
        }
        this.initGrid();
    },
    async mounted() {
        await this.$nextTick(function() {
            this.setPlayground();
        });

        new Promise((resolve, reject) => {
            this.$store.dispatch("getGames")
                .then(() => {
                    resolve(this.fetchedData = true)
                })
                .catch((error) => reject(error));
        })
            .then(async () => {
                await this.setGame();
                await this.updateGrid()
                    .then(async () => {
                        await this.checkDuplicates();
                        this.occurrenceNumbers();
                        this.initGame();
                    })
                    .catch(error => {
                        if(this.$config.debug) {
                            console.log(error);
                        }
                    });
            })
            .catch(error => {
                if(!this.online) { // Keine Spiele vorhanden und Nutzer ist offline
                    this.goOffline();
                }
                else { // Da ist irgendwas anderes im argen
                    this.$store.dispatch("showToast",{
                        msg: "Ein Fehler ist aufgetreten. Bitte lade die Seite neu.",
                        type: "warn",
                        timeout: 5000
                    });
                    if(this.$config.debug) {
                        console.log(error);
                    }
                }
            })
            .finally(() => {
                this.$Progress.finish();
                this.setTitle();
            });
    },
    methods: {
        /**
         * Setzt den Browser-Titel
         */
        setTitle() {
            if(this.game.archive) {
                this.meta.title = "Archiv #" + this.game.nr;
            }
        },
        setPlayground() {
            this.html.keyboard = document.getElementById("game-keyboard");
            this.html.grid = document.getElementById("game-grid");
            this.setGameSize();
        },
        setGameSize() {
            const vh = document.documentElement.clientHeight,
                gameSize = vh-this.html.header,
                gridSize = gameSize-this.html.keyboard.offsetHeight;

            if(document.body.style.aspectRatio === undefined) {
                this.setRatio();
            }
            else if(document.documentElement.clientWidth < 640 && gridSize >= 338) { // 338 x 338 px is the smallest possible size
                this.html.grid.style.maxHeight = gridSize + "px";
            }
        },
        setRatio() {
            this.html.square = document.getElementById("game-square");

            const vh = window.innerHeight, gameSize = vh-this.html.keyboard.offsetHeight-this.html.header,
                ratio = 1, // Aspect Ratio
                px = 16*2, maxWidth = 570;

            let height = gameSize, width = ratio*gameSize; // Breite errechnet mit der Ratio anhand der verfügbaren Höhe

            if(width > (window.innerWidth-px)) { // Breite ist größer als Display
                width = window.innerWidth-px;
                height = width/ratio;
            }
            else if(width > maxWidth) {
                width = maxWidth;
                height = width/ratio;
            }

            this.html.square.style.height = height.toFixed(0) + "px";
            this.html.square.style.width = width.toFixed(0) + "px";
        },
        async setGame() {
            this.game = {...this.game,...this.puzzles[this.game.level]};
            this.game.locked = this.locked;

            this.game = {
                ...this.game,...this.$store.getters.getGameById(this.game.level,this.game.date) || {
                    end: false,
                    errors: 0,
                    level: this.game.level,
                    nr: this.game.nr,
                    score: this.clean(this.game.sudoku),
                    sudoku: this.game.sudoku,
                    time: 0
                }
            };

            this.game.solution = this.decrypt(this.game.sudoku);

            if(!this.game.end && !this.paywall) {
                await this.updateGame();
            }
            else {
                await this.$store.commit("setCurrentGame",this.game);
            }

            this.timer.total = this.game.time;
        },
        /**
         * End of the game; set the user stats
         */
        async setStats() {
            const stats = this.$store.getters.getStats;

            stats.games++;
            stats.time += this.timer.total;
            stats.errors += this.game.errors;
            stats.levels[this.game.level].games++;
            stats.levels[this.game.level].time += this.timer.total;
            stats.levels[this.game.level].errors += this.game.errors;

            await this.$store.commit("setStats",stats);
            await this.$store.dispatch("writeData",true);
            this.$store.dispatch("setLevels");
            this.$store.commit("setLevel",{
                level: this.game.level,
                key: "end",
                val: true
            });

        },
        /**
         *
         * @returns {Promise<unknown>}
         */
        updateGame() {
            return new Promise(async (resolve) => {
                await this.$store.commit("setCurrentGame", this.game);
                await this.$store.commit("setGame", {
                    date: this.game.date,
                    level: this.game.level,
                    end: this.game.end,
                    errors: this.game.errors,
                    nr: this.game.nr,
                    score: this.game.score,
                    setup: this.game.setup,
                    sudoku: this.game.sudoku,
                    time: this.timer.total
                });
                this.$store.dispatch("writeData");
                resolve(true);
            });
        },
        initGrid() {
            /**
             * grid: {
             *  row:[{
             *      animation: {
             *          shake: {}
             *      },
             *      col:[{
             *          number: String,
             *          animations: {
             *              bounce: {},
             *              flip: {}
             *          }
             *      }]
             *  }]
             * }
             */
            for(let row = 0;row < this.$config.setup.game.rows;row++) {
                this.grid[row] = {
                    animations: {
                        shake: false
                    },
                    column: [],
                };
                for(let column = 0;column < this.$config.setup.game.cols;column++) {
                    let border = this.cellBorderBuilder(row,column);
                    this.grid[row].column[column] = {
                        index: false,
                        block: this.getBlock(row+1,column+1),
                        empty: false,
                        duplicate: false,
                        class: border.class,
                        candidates: null,
                        focus: false,
                        number: "",
                        animations: {
                            bounce: false,
                            flip: false,
                            jump: false
                        }
                    };
                    this.rows[row].push(this.grid[row].column[column]);
                    this.cols[column].push(this.grid[row].column[column]);
                    this.blocks[this.grid[row].column[column].block-1].push(this.grid[row].column[column]);

                }
            }
        },
        /**
         *
         * @returns {Promise<Awaited<unknown>[]>}
         */
        updateGrid() {
            let promises = [], insert = false, index = 0, emptyIndex = 0;

            for(let row = 0;row<this.grid.length;row++) {
                for(let column = 0;column<this.grid[row].column.length;column++) {
                    promises.push(new Promise((resolve) => {
                        insert = this.$config.setup.game.range.indexOf(this.game.sudoku[row][column]) === -1;
                        this.grid[row].column[column].index = index;
                        if(insert) {
                            this.grid[row].column[column].class += " cell-empty";
                            this.grid[row].column[column].empty = true;
                            this.grid[row].column[column].candidates = this.$config.setup.game.range.reduce((a, v) => ({ ...a, [v]: this.game.score[row][column][v] ?? false}),null);
                            this.grid[row].column[column].number = this.game.score[row][column].constructor !== Object ? this.game.score[row][column] : "";
                            if(emptyIndex === 0) {
                                this.focus.row = row;
                                this.focus.col = column;
                                this.grid[row].column[column].focus = true;
                            }
                            emptyIndex++;
                        }
                        else {
                            this.grid[row].column[column].class += " cell-filled";
                            this.grid[row].column[column].number = this.game.sudoku[row][column];
                        }
                        index++;
                        resolve();
                    }));
                }
            }

            return Promise.all(promises);
        },
        resetGrid() {
            this.grid[this.focus.row].column[this.focus.col].focus = false;
            this.resetDuplicates();
            this.resetOccurrenceNumbers();
        },
        /**
         *
         * @param mode
         */
        switchSetup(mode = "candidate") {
            if(mode !== "helpMode") {
                this.game.setup[mode] = !this.game.setup[mode];
                this.updateGame();
            }
            else {
                const status = !this.$store.getters.isHelpMode;
                this.$store.commit("setSetup",{
                    key: mode,
                    _class: null,
                    status: status
                });
                this.$store.dispatch("writeToLocalStorage", {
                    key: "pref-" + mode.toLowerCase(),
                    val: status
                });
            }
        },
        /**
         *
         * @param row
         * @param col
         * @returns {number}
         */
        getBlock(row,col) {
            const sqrt = Math.sqrt(this.$config.setup.game.rows);
            const blockCol = Math.ceil(col/sqrt); // 1,2,3
            const blockRow = Math.ceil(row/sqrt)-1; // 0,1,2

            return (blockRow*sqrt)+blockCol;
        },
        /**
         *
         * @param x
         * @param y
         * @returns {{edge: {top: boolean, left: boolean, bottom: boolean, right: boolean}, class: string}}
         */
        cellBorderBuilder(x,y) {
            let classname = "cell-default", edge = {
                top: false,
                right: false,
                bottom: false,
                left: false
            };

            if(x === 0 || x%3 === 0) {
                classname += " border-t-slate-500 border-t-2 dark:border-t-slate-100";
                edge.top = true;
            }

            if(y%this.$config.setup.game.cols === 0 || y%3 === 0) {
                classname += " border-l-slate-500 border-l-2 dark:border-l-slate-100";
                edge.left = true;
            }

            if(x === this.$config.setup.game.rows-1) {
                classname += " border-b border-b-2 border-b-slate-500 dark:border-b-slate-100";
                edge.bottom = true;
            }

            if(y%this.$config.setup.game.cols === this.$config.setup.game.cols-1) {
                classname += " border-r border-r-2 border-r-slate-500 dark:border-r-slate-100";
                edge.right = true;
            }

            return { class: classname, edge: edge };
        },
        initGame() {
            if(!this.locked && !this.paywall && this.game.end === false) {
                this.enableKeyboard();
                this.enableTimer();

                if(this.showModal !== true) {
                    this.startTimer();
                }
            }
        },
        /**
         *
         * @returns {Promise<void>}
         */
        async restartGame() {
            if(!this.locked && this.game.end === false) {
                // this.disableKeyboard();
                this.disableTimer();
                this.stopTimer();
                this.event("RestartGame", {
                    game_nr: this.game.nr,
                    timer: this.timer.total
                });
                this.timer.total = 0;
                this.timer.pause = false;
                this.game.time = 0;
                this.game.errors = 0;
                this.game.score = this.clean(this.game.sudoku);
                await this.updateGame().finally(async () => {
                    await this.resetGrid();
                    await this.updateGrid()
                        .then(async () => {
                            // this.enableKeyboard();
                            this.enableTimer();
                            this.startTimer();
                        })
                });
            }
        },
        openStats() {
            let params = {};

            // this.keyboard.show = false;

            if(this.game.archive) {
                params.nr = this.game.nr;
            }

            router.push({
                name: "Stats",
                params: params
            });
        },
        /**
         *
         * @param row
         * @param col
         * @param focus
         */
        focusCell(row = 0,col = 0,focus = true) {
            this.grid[this.focus.row].column[this.focus.col].focus = false;
            this.focus.row = row;
            this.focus.col = col;
            this.$store.commit("setCurrentFocus",row,col);

            if(focus === true && this.grid[row] !== undefined && this.grid[row].column[col] !== undefined) {
                this.grid[row].column[col].focus = true;
            }
        },
        /**
         * UX-Helper for user selected fields
         * @param row
         * @param col
         */
        clickCell(row,col) {
            if(this.game.end === false && this.grid[row].column[col].empty === true) {
                this.focusCell(row,col);
            }
        },
        /**
         * Init, disable & reset keyboard eventListener
         */
        enableKeyboard() {
            window.addEventListener("keydown",this.enterKeyboard);
        },
        disableKeyboard() {
            window.removeEventListener("keydown",this.enterKeyboard);
        },
        /**
         *
         * @param code
         * @param e
         */
        simKeyboardEnter(code,e) {
            window.dispatchEvent(new KeyboardEvent("keydown", {
                "keyCode": code
            }));

            if(e) {
                e.preventDefault();
            }
        },
        /**
         *
         * @param e
         * @returns {Promise<void>}
         */
        enterKeyboard: async function(e) {
            const charCode = e.which ? e.which : e.keyCode,
                row = this.focus.row,
                col = this.focus.col,
                elm = this.grid[row].column[col],
                codes = {
                  38: -this.$config.setup.game.cols,
                  40: this.$config.setup.game.rows,
                  39: 1,
                  37: -1
                };

            /**
             * Enter number
             *
             * 49 - 57: Numbers 1 - 9
             * 97 - 105: Numpad: 1 - 9
             */
            if(((charCode >= 49 && charCode <= 57) || (charCode >= 97 && charCode <= 105)) && elm.empty === true && !this.timer.pause) {
                const number = charCode <= 57 ? charCode-48 : charCode-96;

                if(this.game.setup.candidate === true) {
                    elm.number = "";
                    elm.candidates[number] = !elm.candidates[number];
                    this.game.score[row][col] = elm.candidates;
                    await this.checkDuplicates();
                    this.occurrenceNumbers();
                }
                else {
                    elm.number = number;
                    elm.candidates = Object.keys(elm.candidates).reduce((accumulator, key) => {
                        return { ...accumulator, [key]: false };
                    },null);
                    await this.checkDuplicates();
                    this.occurrenceNumbers();
                    if(number !== this.game.score[row][col] && elm.duplicate === true)  {
                        this.game.errors++;
                    }
                    this.game.score[row][col] = number;

                }

                if(this.isReady()) { // All fields are filled
                    this.verify();
                }
                else {
                    await this.updateGame();
                }
            }
            else if (charCode === 13) { // Enter
                // nothing
            }
            else if (charCode === 32 && !this.showModal) { // Spacebar
                this.toggleTimer();
            }
            /**
             * Delete number
             *
             * 48: 0
             * 96: numpad 0
             * 46: delete
             * 8: backspace
             */
            else if((charCode === 48 || charCode === 96 || charCode === 46 || charCode === 8) && !this.timer.pause) {
                elm.candidates = Object.keys(elm.candidates).reduce((accumulator, key) => {
                    return { ...accumulator, [key]: false };
                },null);
                elm.number = "";
                this.game.score[row][col] = "";
                await this.checkDuplicates();
                this.occurrenceNumbers();
                await this.updateGame();
            }
            /**
             * 9: tab
             * 9 + shift: tab back
             */
            else if(e.shiftKey && charCode === 9 && !this.timer.pause) { // shift+tab
                this.nextCell(-1,row,col,charCode,elm.index);
            }
            else if(!e.shiftKey && charCode === 9 && !this.timer.pause) { // tab
                this.nextCell(+1,row,col,charCode,elm.index);
            }
            /**
             * 37: left
             * 38: up
             * 39: right
             * 40: down
             */
            else if((charCode === 37 || charCode === 38 || charCode === 39 || charCode === 40) && !this.timer.pause) {
                this.nextCell(codes[charCode],row,col,charCode,elm.index);
            }

            e.preventDefault();

        },
        /**
         *
         * @param amount
         * @param row
         * @param col
         * @param charCode
         * @param index
         */
        nextCell(amount,row,col,charCode,index) {
            if(amount > -1) { // Forward [tab, arrow right, arrow down]
                if(amount < 2) { // One step [tab, arrow right]
                    // If charCode == 39 (right)
                    if(charCode === 39) {
                        // Next col > total = stay in the row and collect the first possible col; Same row, but first col
                        this.focusCell(row,(col+2) > this.$config.setup.game.cols ? 0 : col+1);
                    }
                    else {
                        const next = this.nextIndex(row,col,index);
                        if(next !== false) {
                            this.focusCell(next.row,next.col);
                        }
                    }
                }
                else { // 9 steps [arrow down]
                    this.focusCell((row+2) > this.$config.setup.game.rows ? 0 : row+1,col);
                }
            }
            else { // Backward [shift + tab, arrow left, arrow up]
                if(amount > -2) { // One step [tab shift, arrow right]
                    // If charCode == 37 (left) && next row < currentRow stay in the row and collect the last possible col
                    if(charCode === 37) {
                        // Prev col < 0 cols = stay in the row and collect the last possible col; Same row, but last col
                        this.focusCell(row,(col-1) < 0 ? this.$config.setup.game.cols-1 : col-1);
                    }
                    else {
                        const next = this.nextIndex(row,col,index,false);
                        if(next !== false) {
                            this.focusCell(next.row,next.col);
                        }
                    }
                }
                else { // 9 steps [arrow up]
                    this.focusCell((row-1) < 0 ?  this.$config.setup.game.rows-1 : row-1,col);
                }
            }

        },
        /**
         *
         * @param row
         * @param col
         * @param index
         * @param scratch
         * @returns {boolean|{col: *, row: number}}
         */
        nextIndex(row,col,index,scratch = true) {
            if(scratch) {
                const nextRow = this.grid.findIndex(row => row.column.find(col => col.empty === true && col.index > index));
                const nextCol = this.grid[nextRow > -1 ? nextRow : row].column.findIndex(col => col.empty === true && col.index > index);

                if(nextCol > -1) {
                    return {
                        row: nextRow,
                        col: nextCol
                    }
                }

                return false;
            }
            else {
                let r = row-1, c = this.$config.setup.game.cols-1;

                // If current row contains empty cells && has index < current index = use current row
                if(this.grid[row].column.some(col => col.empty === true && col.index < index)) {
                    for(c;c>=0;c--) {
                        if(this.grid[row].column[c].empty === true && this.grid[row].column[c].index < index) {
                            col = c;
                            break;
                        }
                    }
                    return {
                        row: row,
                        col: col
                    }
                }
                else if(row > 0) {
                    loop:
                        for(r;r>=0;r--) {
                            for(c;c>=0;c--) {
                                if(this.grid[r].column[c].empty === true && this.grid[r].column[c].index < index) {
                                    row = r;
                                    col = c;
                                    break loop;
                                }
                            }
                    }
                    return {
                        row: row,
                        col: col
                    }
                }
                return false;
            }
        },
        /**
         * Check, if sudoku is fulfilled
         * @returns {boolean}
         */
        isReady() {
            return this.game.score.every(function(row) {
                return row.every(function(col) {
                    return typeof col === "number";
                });
            });
        },
        /**
         * Check all cells, rows and blocks for duplicates
         * @returns {Promise<void>}
         */
        async checkDuplicates() {
            await this.cols
                .forEach(col => {
                    col.map(cell => cell.duplicate = false);
                    col.filter(cell => col.filter(c => typeof c.number === "number" && cell.number === c.number).length > 1)
                        .map(cell => cell.duplicate = true)
                });

            await this.rows
                .forEach(row => row.filter(cell => row.filter(c => typeof c.number === "number" && cell.number === c.number).length > 1)
                    .map(cell => cell.duplicate = true));

            await this.blocks
                .forEach(block => block.filter(cell => block.filter(c => typeof c.number === "number" && cell.number === c.number).length > 1)
                    .map(cell => cell.duplicate = true));
        },
        resetDuplicates() {
            this.blocks
                .forEach(block => block.filter(cell => cell.duplicate === true)
                    .map(cell => cell.duplicate = false));
        },
        resetOccurrenceNumbers() {
            this.numbers = this.$config.setup.game.range.reduce((a, v) => ({ ...a, [v]: 0}),null);
        },
        occurrenceNumbers() {
            this.resetOccurrenceNumbers();

            this.rows
                // .forEach(row => row.filter(cell => typeof cell.number === "number" && ((cell.empty && !cell.duplicate) || !cell.empty))
                .forEach(row => row.filter(cell => typeof cell.number === "number")
                    .map(cell => {
                        this.numbers[cell.number]++;
                    })
                );
        },
        /**
         * Compare the user input and the sudoku
         *
         * @returns {this is *[]}
         */
        compare() {
            return this.game.solution.every((arr,row) => {
                return arr.every((number,col) => {
                    return number === this.game.score[row][col];
                });
            });
        },
        /**
         * Check, if the sudoku is correct
         */
        async verify() {
            if(this.compare()) {
                this.disableKeyboard();
                this.disableTimer();
                this.timer.pause = true;
                this.game.end = true;
                this.game.time = this.timer.total;
                await this.updateGame();
                await this.setStats();
                this.event("GameFinished", {
                    game_nr: this.game.nr,
                    timer: this.timer.total
                });
                this.$store.dispatch("showToast",{
                    msg: this.$config.content.messages["success"][Math.floor(Math.random() * this.$config.content.messages["success"].length)],
                    type: "success",
                    timeout: 2000,
                    callback: () => {
                        this.openStats();
                    }
                });
            }
            else {
                this.event("GameFailed", {
                    game_nr: this.game.nr,
                    timer: this.timer.total
                });
                this.$store.dispatch("showToast",{
                    msg: this.$config.content.messages["failed"][Math.floor(Math.random() * this.$config.content.messages["failed"].length)],
                    type: "warn",
                    timeout: 2000,
                    callback: () => {
                        this.openStats();
                    }
                })
            }
        },
        generatePdf() {
            /**
             * TODO: import jsPDF only for print
             * @type {module:jspdf.jsPDF}
             */
            const doc = new jsPDF();
            // const today = new Date();
            const dateObject = new Date(this.date);
            const date = dateObject.getDate() + ". " + this.$config.date.months[dateObject.getMonth() + 1] + " " + dateObject.getFullYear();

            /**
             * First row: "sudoku.io" / x15 y15 / font: helvetica, normal, 16, gray
             */
            doc.setFontSize(15);
            doc.setTextColor(160,160,160);
            doc.text(this.$config.common.domain, 15, 15);

            /**
             * Second row: "Sudoku 'Leicht' vom 06. Januar 2023" / font: times, bold, 23, black
             */
            doc.setFont("times","bold");
            doc.setTextColor(0,0,0);
            doc.setFontSize(23);
            doc.text("Sudoku \"" + this.$config.setup.game.levels[this.game.level] + "\" vom " + date, 15, 24);

            doc.autoTable({
                body: this.clean(this.game.sudoku),
                tableLineColor: 20,
                tableLineWidth: 1,
                theme: "grid",
                margin: {
                    top: 45,
                    bottom: 0,
                    left: 24, // 35
                    right: 0 // 35
                },
                styles: {
                    font: "helvetica",
                    fontStyle: "bold",
                    fontSize: 26,
                    textColor: 0,
                    cellPadding: {
                        top: 3, // 3
                        right: 3, // 3
                        bottom: 2, // 2
                        left: 3 // 3
                    },
                    minCellHeight: 18,
                    minCellWidth: 18,
                    halign: "center",
                    valign: "middle",
                    lineColor: 20,
                    lineWidth: {
                        top: 0.1,
                        right: 0.1,
                        bottom: 0.1,
                        left: 0.1
                    },
                },
                tableWidth: "wrap",
                didParseCell: (table) => {
                    if(this.grid[table.row.index].column[table.column.index].empty === false) {
                        table.cell.styles.fillColor = [238,238,238];
                    }

                    const border = this.cellBorderBuilder(table.row.index,table.column.index);

                    table.cell.styles.lineWidth = {
                        top: border.edge.top ? 1 : 0.1,
                        right: border.edge.right ? 1 : 0.1,
                        bottom: border.edge.bottom ? (table.row.index < 8 ? 1 : 0) : 0.1,
                        left: border.edge.left ? 1 : 0.1
                    };
                }
            });

            doc.setLineWidth(0.2);
            doc.line(5, 280, 205, 280);
            doc.setTextColor(0,0,0);
            doc.setFont("helvetica","normal");
            doc.setFontSize(15);
            doc.text ("Sudoku als PDF: " + this.meta.canonical, 15, 290);

            doc.setProperties({
                title: "Sudoku - Schwierigkeit: " + this.$config.setup.game.levels[this.game.level],
                subject: this.meta.title,
                author: this.$config.name,
                keywords: "Sudoku",
            });

            doc.output("dataurlnewwindow",{
                filename: "sudoku-" + this.date + "-" + this.$config.setup.game.levels[this.game.level].toLowerCase()
            });
            // doc.autoPrint();

            this.event("PrintGame", {
                game_nr: this.game.nr
            });

        },
        /**
         * User leave the focus of the game
         */
        onBlur() {
            if(this.timer.pause || !this.timer.autoPause || !this.timer.interval) {
                return;
            }

            window.addEventListener("focus",this.onFocus,{once:true});
            this.timer.countdown.timeUntilPause = 10;

            this.timer.countdown.interval = setInterval(() => {
                if(this.timer.countdown.timeUntilPause <= 0) {
                    clearInterval(this.timer.countdown.interval);
                    this.toggleTimer();
                    this.updateGame();
                }
                this.timer.countdown.timeUntilPause -= 1;
            }, 1000);
        },
        /**
         * Game has focus
         */
        onFocus() {
            clearInterval(this.timer.countdown.interval);
        },
        /**
         *
         * @returns {boolean}
         */
        onBeforeUnload() {
            this.leaving();
            return false;
        },
        /**
         * Enable & disable timer eventListeners
         */
        enableTimer() {
            addEventListener("pagehide",this.onBeforeUnload);
            addEventListener("blur",this.onBlur);
        },
        disableTimer() {
            removeEventListener("pagehide",this.onBeforeUnload);
            removeEventListener("blur",this.onBlur);
        },
        /**
         * Activate timer interval
         */
        startTimer() {
            this.clearTimer();
            this.timer.interval = setInterval(() => {
                this.timer.total++;
                this.game.time = this.timer.total;
            },1000);
        },
        /**
         * Toggle timer
         */
        toggleTimer() {
            if(this.game.end === false && !this.locked && !this.paywall) {
                this.timer.pause = !this.timer.pause;
            }
        },
        /**
         * Pause timer
         */
        pauseTimer() {
            if(this.game.end === false && !this.locked && !this.paywall) {
                this.timer.pause = true;
            }
        },
        /**
         * Stop timer
         */
        stopTimer() {
            this.clearTimer();
        },
        /**
         * Clear timer interval
         */
        clearTimer() {
            clearInterval(this.timer.interval);
            this.timer.interval = null;
        },
        /**
         * Leave the game
         */
        leaving() {
            this.disableKeyboard();
            if(!this.game.end && !this.locked) {
                this.updateGame().finally(() => {
                    this.clearTimer();
                    this.disableTimer();
                });
            }
        }
    },
    beforeUnmount() {
        this.leaving();
    },
}
</script>
