<template>
    <!-- Modal body -->
    <div class="py-3 px-5 sm:p-5 border-b dark:border-gray-600">
        <span class="mb-2 inline-flex items-center bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-gray-900 dark:text-gray-300">
            <span class="w-2 h-2 mr-1 bg-gray-500 rounded-full"></span>
            Alle Spiele ({{ levels[level] }})
        </span>
        <div class="flex justify-center items-stretch gap-4 dark:text-gray-300">
            <div class="text-center basis-1/3">
                <div class="flex-none font-bold text-2xl sm:text-3xl">{{ stats.totalGames }}</div>
                <label class="block text-sm sm:text-base break-words">x gelöst</label>
            </div>
            <div class="text-center basis-1/3">
                <div class="inline-flex items-center font-bold text-2xl sm:text-3xl whitespace-nowrap">
                    <icon icon="clock" classes="w-6 h-6 mr-1 text-gray-700 dark:text-gray-300"></icon>
                    {{ stats.avgTime }}
                </div>
                <label class="block text-sm sm:text-base break-words">Ø Zeit</label>
            </div>
            <div class="text-center basis-1/3">
                <div class="inline-flex items-center font-bold text-2xl sm:text-3xl whitespace-nowrap">
                    <icon icon="exclamation-circle" classes="w-6 h-6 mr-1 text-gray-700 dark:text-gray-300"></icon>
                    {{ stats.avgErrors }}
                </div>
                <label class="block text-sm sm:text-base break-words">Ø Fehlerrate</label>
            </div>
        </div>
    </div>
    <div class="py-3 px-5 sm:p-5 border-b dark:border-gray-600 bg-gray-100 dark:bg-gray-700" v-if="!stats.locked">
        <div v-if="game && game.time > 0">
            <div v-if="!game.end" class="relative mb-2 inline-flex items-center bg-red-100 text-red-800 text-xs font-medium pr-2.5 pl-5.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                <span class="flex h-3 w-3 mr-1 absolute left-2 top-1">
                    <span class="animate-[ping_2s_ease-in-out_infinite] absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                    <span class="relative inline-flex rounded-full border-2 border-white h-3 w-3 bg-red-500 dark:border-gray-800"></span>
                </span>
                Live-Statistik
            </div>
            <div v-else class="mb-2 inline-flex items-center bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-800 dark:text-green-300">
                <span class="w-2 h-2 mr-1 bg-green-500 rounded-full"></span>
                {{ game.archive ? "Sudoku #" + game.nr : "Spiel heute" }}
            </div>
            <ul class="space-y-3" v-if="game.time > 0">
                <li class="flex items-center">
                    <icon v-if="!game.end" icon="pause-circle" classes="w-7 h-7 mr-2 text-gray-700 dark:text-gray-300"></icon>
                    <icon v-else icon="check-circle" classes="w-7 h-7 mr-2 text-green-500"></icon>
                    <p v-if="game.end" class="text-gray-800 dark:text-gray-300 text-base font-bold">Sudoku {{ game.errors === 0 ? "fehlerfrei " : "" }}gelöst!</p>
                    <p v-else class="text-gray-800 dark:text-gray-300 text-base font-bold">Sudoku pausiert</p>
                </li>
                <li class="flex" :class="compareTime ? null : 'items-center'">
                    <icon icon="clock" classes="w-7 h-7 mr-2 text-gray-700 dark:text-gray-300"></icon>
                    <div>
                        <strong class="text-gray-800 dark:text-gray-300 text-base whitespace-nowrap">{{ getReadableTime(game.time) }}</strong>
                        <div v-if="compareTime" class="flex items-center">
                            <p class="text-gray-600 dark:text-gray-400 text-sm first-letter:capitalize">{{ (!game.end && compareTime !== "gt" ? "Bisher " : "") + (compareTime === "lt" ? "schneller als dein" : (compareTime === "eq" ? "im" : "langsamer als dein")) }} Durchschnitt</p>
                            <span :class="compareTime === 'lt' ? 'bg-green-200 dark:bg-green-300' : (compareTime === 'eq' ? 'bg-gray-300 dark:bg-gray-400' : 'bg-red-100 dark:bg-red-200')" class="ml-2 w-6 h-6 rounded-full p-1 inline-flex items-center justify-center shrink-0">
                            <svg aria-hidden="true" :class="compareTime === 'lt' ? 'text-green-700 dark:text-green-800' : (compareTime === 'eq' ? 'text-gray-800 dark:text-gray-900' : 'text-red-500 dark:text-red-600')" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path v-if="compareTime === 'lt'" fill-rule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clip-rule="evenodd" />
                                <path v-else-if="compareTime === 'eq'" fill-rule="evenodd" d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z" clip-rule="evenodd" />
                                <path v-else d="M6.28 5.22a.75.75 0 00-1.06 1.06l7.22 7.22H6.75a.75.75 0 000 1.5h7.5a.747.747 0 00.75-.75v-7.5a.75.75 0 00-1.5 0v5.69L6.28 5.22z" />
                            </svg>
                        </span>
                        </div>
                    </div>
                </li>
                <li class="flex" :class="compareErrors ? null : 'items-center'">
                    <icon icon="exclamation-circle" classes="w-7 h-7 mr-2 text-gray-700 dark:text-gray-300"></icon>
                    <div>
                        <strong class="text-gray-800 dark:text-gray-300 text-base whitespace-nowrap">{{ game.errors }} Fehler</strong>
                        <div v-if="compareErrors" class="flex items-center">
                            <p class="text-gray-600 dark:text-gray-400 text-sm first-letter:capitalize">{{ (!game.end && compareErrors !== "gt" ? "Bisher " : "") + (compareErrors === "lt" ? "besser als dein" : (compareErrors === "eq" ? "im" : "mehr als dein")) }} Durchschnitt</p>
                            <span :class="compareErrors === 'lt' ? 'bg-green-200 dark:bg-green-300' : (compareErrors === 'eq' ? 'bg-gray-300 dark:bg-gray-400' : 'bg-red-100 dark:bg-red-200')" class="ml-2 w-6 h-6 rounded-full p-1 inline-flex items-center justify-center shrink-0">
                            <svg aria-hidden="true" :class="compareErrors === 'lt' ? 'text-green-700 dark:text-green-800' : (compareErrors === 'eq' ? 'text-gray-800 dark:text-gray-900' : 'text-red-500 dark:text-red-600')" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path v-if="compareErrors === 'lt'" fill-rule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clip-rule="evenodd" />
                                <path v-else-if="compareErrors === 'eq'" fill-rule="evenodd" d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z" clip-rule="evenodd" />
                                <path v-else d="M6.28 5.22a.75.75 0 00-1.06 1.06l7.22 7.22H6.75a.75.75 0 000 1.5h7.5a.747.747 0 00.75-.75v-7.5a.75.75 0 00-1.5 0v5.69L6.28 5.22z" />
                            </svg>
                        </span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div v-else class="relative pl-6 text-sm text-gray-600 dark:text-gray-400">
            <icon icon="information-circle" classes="h-5 w-5 absolute top-0 left-0 text-gray-500 dark:text-gray-400"></icon>
            <p>Sudoku noch nicht gestartet. {{ $addressingUser('Du kannst','Sie können') }} die Schwierigkeitsstufe "{{ levels[level] }}" heute noch lösen.</p>
        </div>
    </div>
    <!-- /Modal body -->
</template>

<script>
import Icon from "../vendor/publisher/components/Icon.vue"
import Actions from "./Actions.vue";

export default {
    name: "LevelStats",
    props: ["stats","currentGame","level"],
    components: {
        Actions,
        Icon,
    },
    computed: {
        /**
         *
         * @returns {any}
         */
        game() {
            return this.currentGame.level === this.level ? this.currentGame : this.$store.getters.getGameById(this.level);
        },
        /**
         *
         * @returns {string|null}
         */
        compareTime() {
            if((this.stats.totalGames > 1 && this.game.end) || (this.stats.totalGames > 0 && !this.game.end)) {
                const avgTime = this.getAvgErrors(this.game.end ? this.stats.totalTime - this.game.time : this.stats.totalTime, this.game.end ? this.stats.totalGames - 1 : this.stats.totalGames);

                return avgTime === this.game.time ? "eq" : (this.game.time < avgTime ? "lt" : "gt");
            }

            return null;
        },
        /**
         *
         * @returns {string|null}
         */
        compareErrors() {
            if((this.stats.totalGames > 1 && this.game.end) || (this.stats.totalGames > 0 && !this.game.end)) {
                const avgErrors = this.getAvgErrors(this.game.end ? this.stats.totalErrors - this.game.errors : this.stats.totalErrors, this.game.end ? this.stats.totalGames - 1 : this.stats.totalGames);

                return avgErrors === this.game.errors ? "eq" : (this.game.errors < avgErrors ? "lt" : "gt");
            }

            return null;
        }
    },
    data() {
        return {
            levels: this.$config.setup.game.levels,
        }
    }
}
</script>