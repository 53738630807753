import { createWebHistory, createRouter } from "vue-router";

import { config } from "../config"
import Home from "../pages/Home"
import Stats from "../pages/Stats"

export const routes = [
    {
        name: "Home",
        path: '/:level(' + Object.values(config.setup.game.levels).map(level => level.toLowerCase()).slice(1).join("|") + ')?',
        props: route => ({
            level: Object.keys(config.setup.game.levels).find(key => config.setup.game.levels[key].toLowerCase() === route.params.level) || Object.keys(config.setup.game.levels)[0]
        }),
        component: Home,
        meta: {
            finishProgress: false,
            title: "Sudoku Smart - Sudoku kostenlos online spielen",
            description: "Beginne als Sudoku-Anfänger und schalte die schwierigste Sudoku-Stufe frei. Deine Erfolge werden in deinen persönlichen Statistiken festgehalten.",
            robots: "index, follow, noarchive, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        },
        children: [
            {
                name: "Stats",
                path: "statistiken",
                component: Stats,
                meta: {
                    showModal: true,
                    title: "Statistiken",
                    description: "",
                    robots: "noindex, follow, noarchive"
                },
                beforeEnter(to,from,next) {
                    to.params.level = from.params.level || config.setup.game.levels[Object.keys(config.setup.game.levels)[0]].toLowerCase();
                    to.query.date = from.query.date;
                    next();
                },
            }
        ]
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if(to.hash) {
            return {
                left: 0,
                top: 30,
                el: to.hash,
                behavior: "smooth",
            }
        }
        else if(savedPosition) {
            return savedPosition;
        }
        else {
            return {
                behavior: "smooth",
                left: 0,
                top: 0
            }
        }
    }
});

export default router;
