// import { config } from "../config"

export default {
    data: function() {
        return {
            emojis: {
                1: "1️⃣",
                2: "2️⃣",
                3: "3️⃣",
                4: "4️⃣",
                5: "5️⃣",
                6: "6️⃣",
                7: "7️⃣",
                8: "8️⃣",
                9: "9️⃣"
            }
        }
    },
    methods: {
        /**
         *
         * @param nr
         * @param level
         * @param time
         * @param errors
         * @param sudoku
         * @returns {string}
         */
        buildShareText(nr,level,time = 0,errors = 0,sudoku = []) {
            return "Sudoku #"
                + nr
                + " (" + this.$config.setup.game.levels[level] + ")"
                + " in " + this.getReadableTime(time)
                + (errors === 0 ? " fehlerfrei" : "")
                + " gelöst\n\n"
                + this.buildEmojiSquares(sudoku) + "\n"
                + this.$config.common.domain + " - " + this.$config.name
        },
        /**
         * green medium square: &#129001;
         * yellow medium square: &#129000;
         * grey medium square: &#11036;
         */
        buildEmojiSquares(sudoku = []) {
            let emoji = "";

            sudoku.forEach((row,i) => {
                row.forEach((col,j) => {
                    emoji += this.$config.setup.game.range.indexOf(sudoku[i][j]) === -1 ? "⬜" : this.emojis[sudoku[i][j]];
                });
                emoji += "\n";
            });

            return emoji;
        },

        /**
         *
         * @param nr
         * @param level
         * @param time
         * @param errors
         * @param sudoku
         */
        shareResult(nr,level,time = 0,errors = 0,sudoku = []) {
            const data = {
                title: this.$config.name,
                text: this.buildShareText(nr,level,time,errors,sudoku)
            };

            if(this.$store.getters.canShare) {
                navigator.share(data)
                    .then(() => {
                        // Success share
                        this.event("ShareResult", {
                            game_nr: nr,
                            timer: time
                        });
                    })
                    .catch(ignore => {
                        this.$store.dispatch("showToast",{
                            msg: "Ergebnis kopiert!",
                            type: "success"
                        });
                    });

                this.copyToClipboard(data.text,false);
            }
            else {
                this.copyToClipboard(data.text);
            }
        },
        /**
         *
         * @param text
         * @param toast
         */
        copyToClipboard(text,toast = true) {
            navigator.clipboard.writeText(text).then(() => {
                if(toast === true) {
                    this.$store.dispatch("showToast",{
                        msg: "Ergebnis kopiert!",
                        type: "success"
                    });
                    this.event("CopyResult");
                }
            }, function(err) {
                // console.error('Async: Could not copy text: ', err);
            });
        },
    }
}
