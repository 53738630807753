export const subscription = {
    namespaced: true,
    state: () => ({
        paywall: false,
        levels: {},
        printing: true
    }),
    actions: {
        register: ({dispatch,rootGetters}) => {
            // Register paywall methods
            for (const level of Object.keys(rootGetters.getLevels)) {
                dispatch("paywall",{
                    action: "levels",
                    data: {
                        level: level,
                        games: rootGetters.getStats.games
                    }
                });
            }

            dispatch("paywall",{
                action: "printing"
            })
        },
        /**
         *
         * @param dispatch
         * @param commit
         * @param rootGetters
         */
        boot: ({dispatch,commit,rootGetters}) => {
            const config = rootGetters.getConfig;

            if(config.common["with_authentication"] && config.paywall?.enabled) {
                commit("enable");
                dispatch("register");
            }
            else if(config.debug) {
                console.info("The paywall could not be booted because either no authentication is active or the paywall is not active.");
            }
        },
        /**
         *
         * @param dispatch
         * @param commit
         * @param getters
         * @param action
         * @param data
         * @returns {Promise<unknown>}
         */
        paywall: ({dispatch, commit, rootGetters},{action,data}) => {
            return new Promise((resolve) => {
                const config = rootGetters.getConfig;

                dispatch(action, {
                    data: data,
                    config: config
                })
                    .catch(error => { // paywall for action has an error
                        if(config.debug) {
                            console.warn("Action [" + action + "] reject '" + error + "'. \nData: " + JSON.stringify(data))
                        }
                    })
                    .finally(() => resolve(true));
            });
        },
        afterLogin: (/*{dispatch}*/) => {
            // Action after login
        },
        afterLogout: (/*{dispatch}*/) => {
            // Action after logout
        },
        /**
         *
         * @param getters
         * @param commit
         * @param data
         * @param config
         * @returns {Promise<unknown>}
         */
        levels: ({commit,rootGetters},{data,config}) => {
            return new Promise((resolve, reject) => {
                if (!config.setup.paywall.hasOwnProperty("levels") ||
                    !config.setup.paywall.levels.hasOwnProperty(data.level) ||
                    config.setup.paywall.levels[data.level] !== true) {
                    if (!config.setup.paywall.hasOwnProperty("games") ||
                        !config.setup.paywall["games"] ||
                        data.games < parseInt(config.setup.paywall["games"])) {
                        commit("setLevelPaywall", {
                            level: data.level,
                            val: false
                        })
                        return resolve("Access for paywall action [levels/level: " + data.level + "] allowed");
                    }
                }

                commit("setLevelPaywall", {
                    level: data.level,
                    val: true
                })
                reject("Access for paywall action [levels/level: " + data.level + "] denied")
            });
        },
        /**
         *
         * @param commit
         * @param rootGetters
         * @param config
         * @returns {Promise<unknown>}
         */
        printing: ({commit,rootGetters},{config}) => {
            return new Promise((resolve, reject) => {
                if (!config.setup.paywall.hasOwnProperty("printing") ||
                    config.setup.paywall.printing !== true) {
                    commit("setPrintingPaywall",false)
                    return resolve("Access for paywall action [printing] allowed");
                }

                commit("setPrintingPaywall",true)
                reject("Access for paywall action [printing] denied")
            });
        }
    },
    mutations: {
        /**
         *
         * @param state
         */
        enable: (state) => {
            state.paywall = true;
        },
        /**
         *
         * @param state
         * @param level
         * @param key
         * @param val
         */
        setLevelPaywall: (state,{level,val}) => {
            if(!state.levels.hasOwnProperty(level)) {
                state.levels[level] = {
                    paywall: false
                };
            }
            state.levels[level].paywall = val;
        },
        /**
         *
         * @param state
         * @param status
         */
        setPrintingPaywall: (state,status) => {
            state.printing = status;
        }
    },
    getters: {
        /**
         *
         * @param state
         * @param getters
         * @returns {function(*, ...[*]): *}
         */
        isPaywall: (state,getters) => (type,...args) => {
            return state.paywall && !store.getters["auth/isAuth"] && getters[`${type}`].apply(null,args);
        },
        /**
         *
         * @param state
         * @returns {function(*): boolean|*}
         */
        levels: state => (level) => {
            return state.levels.hasOwnProperty(level) && state.levels[level].hasOwnProperty("paywall") ? state.levels[level].paywall : false;
        },
        /**
         *
         * @param state
         * @returns {function(): boolean}
         */
        printing: state => () => state.printing
    }
}