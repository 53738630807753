export default {
    data() {
        return {
            letters: ['A','B','C','D','E','F','G','H','I']
        }
    },
    methods: {
        /**
         * Solve the sudoku
         * @returns {number[]}
         * @param sudoku
         */
        decrypt(sudoku = []) {
            return sudoku.map(x => x.map(y => isNaN(y) ? this.letters.indexOf(y)+1 : y));
        },
        /**
         * Remove the letters from the grid
         * @param sudoku
         */
        clean(sudoku = []) {
            return sudoku.map(x => x.map(y => isNaN(y) ? "" : y));
        },
        /**
         *
         * @param arrCharKey
         * @returns {string}
         */
        arrayCharKeyToString(arrCharKey = []) {
            const string = arrCharKey.map(function(code) {
                return String.fromCharCode(code).toUpperCase();
            });

            return string.join("");
        },
        onCountdownEnd() {
            setTimeout(this.$router.go(0),1000);
        },
        tilNextDay() {
            const midnight = new Date(new Date().setHours(24,0,0,0));
            return midnight.getTime() - new Date().getTime();
        },
        /**
         *
         * @param time
         * @returns {string}
         */
        getReadableTime(time) {
            const minutes = Math.floor(time / 60) || 0, seconds = Math.floor(time - minutes * 60) || 0;

            return this.padTime(minutes) + "m " + this.padTime(seconds) + "s";
        },
        /**
         *
         * @param time
         * @param games
         * @returns {string}
         */
        getAvgTime(time,games) {
            const avgTime = time/games, minutes = Math.floor(avgTime / 60) || 0, seconds = Math.floor(avgTime - minutes * 60) || 0;

            return this.padTime(minutes) + "m " + this.padTime(seconds) + "s";
        },
        /**
         *
         * @param errors
         * @param games
         * @returns {number}
         */
        getAvgErrors(errors,games) {
            return Math.round(errors/games) || 0;
        },
        /**
         * Return readable timer
         * @param time
         * @returns {*}
         */
        padTime(time) {
            return (time < 10 ? "0" : "") + time;
        },
    }
}
