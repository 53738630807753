let config = {
    identifier: process.env.IDENTIFIER,
    name: process.env.NAME,
    asset_url: process.env.ASSET_URL,

    common: {
        domain: window.location.host,
        revision: process.env.REVISION,
    },

    setup: {
        game: {
            games: Object.keys(JSON.parse(process.env.MIX_GAME_LEVELS)).length,
            levels: JSON.parse(process.env.MIX_GAME_LEVELS),
            rows: parseInt(process.env.MIX_GAME_ROWS),
            cols: parseInt(process.env.MIX_GAME_COLS),
            max_archive_days: parseInt(process.env.MIX_GAME_MAX_ARCHIVE_DAYS),
            // preload_days: parseInt(process.env.MIX_GAME_PRELOAD_DAYS),
            success_to_unlock: parseInt(process.env.MIX_GAME_SUCCESS_TO_UNLOCK),
        }
    },

    layout: {
        colors: {
            success: "bg-brand-500 contrast:bg-orange-500",
            fail: "bg-stone-400",
        },
    },

    content: {
        messages: {
            warn: "Das hat leider nicht geklappt.",
            restart: "Wirklich neustarten? Alle Eingaben werden zurückgesetzt.",
            failed: [
                // TODO: failed messages by current errors
                "Die Lösung ist leider nicht korrekt."
            ],
            success: [
                // TODO: success messages by time
                "Glückwunsch, das Sudoku ist erfolgreich gelöst!"
            ]
        },
        meta: {
            Easy: {
                title: "Sudoku Smart - Sudoku kostenlos online spielen",
                description: "Beginne als Sudoku-Anfänger und schalte die schwierigste Sudoku-Stufe frei. Deine Erfolge werden in deinen persönlichen Statistiken festgehalten.",
                robots: "index, follow, noarchive, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
            },
            Medium: {
                title: "Sudoku auf mittlerer Schwierigkeit",
                description: "Du spielst bereits gelegentlich Sudoku und bist kein Anfänger mehr? Dann versuche dich auf der mittleren Schwierigkeit.",
                robots: "index, follow, noarchive, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
            },
            Hard: {
                title: "Sudoku schwierig - für echte Profis",
                description: "Du spielst schon länger Sudoku und suchst nach einer echten Herausforderung? Dann wird das smarte Sudoku an jetzt dein täglicher Begleiter.",
                robots: "index, follow, noarchive, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
            },
            Expert: {
                title: "Sudoku für absolute Experten",
                description: "Sobald du alle vorherigen Sudoku-Level freigeschaltet hast, steht dir die höchste Schwierigkeitsstufe zur Verfügung. Aber vorsicht, hier bestehen nur echte Sudoku-Experten.",
                robots: "index, follow, noarchive, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
            }
        },
    },

    /**
     * @deprecated
     * Edited by faqs in oliwol publisher
     */
    stores: {
        android: {
            id: process.env.MIX_PUBLISHER_ANDROID_ID
        }
    },

    date: {
        months: {
            1: "Januar",
            2: "Februar",
            3: "März",
            4: "April",
            5: "Mai",
            6: "Juni",
            7: "Juli",
            8: "August",
            9: "September",
            10: "Oktober",
            11: "November",
            12: "Dezember",
        }
    }
}

// Todo: make dynamic; currently static
config.setup.game.range = Array.from({length: config.setup.game.cols}, (_, i) => i + 1);

function preloadPuzzles(preload = window.Publisher.setup?.game["max_archive_days"] ?? 7,puzzles = window.Publisher.setup?.game["games"] ?? 4) {
    return ((preload*2)+2)*puzzles;
}

export { config, preloadPuzzles }
